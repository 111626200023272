var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.data &&
    (_vm.data.jsError === true ||
      (_vm.data.jsError === false &&
        _vm.data.jsLog &&
        _vm.data.jsLog.length > 0)) &&
    _vm.lucideIcon
    ? _c(
        "div",
        { staticClass: "mr-1 cursor-pointer", attrs: { id: _vm.uniqueId } },
        [
          _c(_vm.getLucideIcon(_vm.lucideIcon.name), {
            tag: "component",
            attrs: {
              color: _vm.lucideIcon.color,
              size: _vm.sizeIcon,
              "stroke-width": _vm.strokeWidth,
            },
          }),
          _c(
            "b-tooltip",
            {
              attrs: {
                target: _vm.uniqueId,
                placement: "left",
                triggers: "hover",
              },
            },
            [
              _c("div", {
                staticClass: "text-left p-1 v-bootstrap-tooltip-custom",
                staticStyle: { "font-size": "1rem" },
                domProps: { innerHTML: _vm._s(_vm.data.jsLog) },
              }),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }