<style scoped>
.card >>> table > tbody > tr > td {
	cursor: pointer;
}
</style>

<template>
	<errorContainer :error="erreur" :warning="warning">
		<transition name="slide">
			<div v-if="$screen.width <= 567">
				<h1 :class="[`${!$screen.sm && !$screen.md ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">
					{{ this.FormMSG(50, 'Timesheet details') }}
				</h1>
				<details-timesheet-mobile
					:cur-ts="curTs"
					:ts-days="TsDays"
					:cur-day="curDay"
					@details-timesheet-mobile:download="downloadXls"
					@details-timesheet-mobile:share="sendXlsByEmail"
				/>
			</div>
			<div v-else class="container-layout form p-0">
				<b-row class="animated fadeIn">
					<!-- <pre>{{ curTs }}</pre> -->
					<b-col cols="12" xl="12">
						<h1 :class="[`${!$screen.sm && !$screen.md ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">
							{{ this.FormMSG(50, 'Timesheet details') }}
						</h1>
						<b-card no-body>
							<!-- <pre>{{ curTs }}</pre> -->
							<b-row class="back-with-title cursor-pointer d-flex align-items-center pb-2 pl-0" :class="{ 'mt-16': $isPwa() }">
								<b-col cols="4">
									<button class="btn-transparent" @click="handleClickBack">
										<component :is="getLucideIcon('ArrowLeft')" color="rgba(6, 38, 62, 0.65)" :size="22" class="icon" />
										<span class="title-back-bolder mx-0">
											{{ FormMSG(2011, 'Back') }}
										</span>
									</button>
								</b-col>
								<b-col cols="4" class="d-flex flex-row justify-content-center">
									<!-- <h2 class="text-uppercase">{{ curTs.user.firstName }} {{ curTs.user.name }}</h2> -->
									<span v-if="!isFilmSingle" class="border-encoded p-1"
										>{{ FormMSG(149, 'Encoded by: ') }} <b>{{ encodedBy(curTs) }}</b>
									</span>
								</b-col>
								<b-col cols="4" class="inline-flex align-items-center text-right py-0">
									<div v-if="!isFilmSingle">
										<div class="date-title pr-3">
											<div class="wrap-status justify-content-end">
												<div :class="`status ${classStatus(curTs.validated)}`" style="font-size: 0.7rem">
													{{ curTs.validatedStatus }}
												</div>
											</div>
										</div>
										<div class="name-title pr-3">
											{{ curTs.user.name }} {{ curTs.user.firstName }}
											<span class="divider-dot" v-if="curTs.user.functionName && curTs.user.functionName !== ''" />
											{{ curTs.user.functionName }}
										</div>
									</div>
								</b-col>
							</b-row>
							<b-card-text class="px-3 pt-4">
								<b-row>
									<b-col cols="12" xl="12" class="px-3">
										<div class="card card-border-blue-light">
											<b-row>
												<b-col sm="4">
													<b-form-group :label="FormMSG(10, 'Hours:')" style="font-weight: bold" label-for="hours" :label-cols="0">
														<b-form-input :disabled="true" v-model="curTs.hhmm" type="text" id="hours"></b-form-input>
													</b-form-group>
												</b-col>
												<b-col sm="4">
													<b-form-group :label="FormMSG(140, 'CO2:')" style="font-weight: bold" label-for="co2" :label-cols="0">
														<b-form-input
															:disabled="true"
															:value="this.displayKgCoTwo(curTs.kgCoTwo)"
															type="text"
															id="co2"
														></b-form-input>
													</b-form-group>
												</b-col>
												<b-col v-if="canSeeSalary" sm="4">
													<b-form-group :label="FormMSG(14, 'Salary:')" label-for="salary" style="font-weight: bold" :label-cols="0">
														<b-form-input
															:disabled="true"
															:value="this.displayCurrency(curTs.salary)"
															type="text"
															id="salary"
														></b-form-input>
													</b-form-group>
												</b-col>
												<b-row class="ml-0 w-100" v-if="showOvt() || showOvt1() || showOvt2()">
													<b-col cols="12">
														<fieldset class="scheduler-border border-groove-blue-streak w-100 pb-3">
															<legend class="scheduler-border text-color-blue-streak">
																{{ FormMSG(391, 'Daily Overtime') }}
															</legend>
															<b-row>
																<b-col sm="4" v-if="showOvt()">
																	<b-form-group
																		style="font-weight: bold"
																		:label="FormMSG(11, 'Overtime:')"
																		label-for="ovt"
																		:label-cols="0"
																	>
																		<b-form-input
																			:disabled="true"
																			v-model="curTs.hhmmOvt"
																			type="text"
																			id="ovt"
																		></b-form-input>
																	</b-form-group>
																</b-col>
																<b-col sm="4" v-if="showOvt1()">
																	<b-form-group
																		style="font-weight: bold"
																		:label="FormMSG(121, 'Overtime 1:')"
																		label-for="ovt1"
																		:label-cols="0"
																	>
																		<b-form-input
																			:disabled="true"
																			v-model="curTs.hhmmOvt1"
																			type="text"
																			id="ovt1"
																		></b-form-input>
																	</b-form-group>
																</b-col>
																<b-col sm="4" v-if="showOvt2()">
																	<b-form-group
																		style="font-weight: bold"
																		:label="FormMSG(131, 'Overtime 2:')"
																		label-for="ovt2"
																		:label-cols="0"
																	>
																		<b-form-input
																			:disabled="true"
																			v-model="curTs.hhmmOvt2"
																			type="text"
																			id="ovt2"
																		></b-form-input>
																	</b-form-group>
																</b-col>
															</b-row>
														</fieldset>
													</b-col>
												</b-row>
												<b-row class="ml-0 w-100" v-if="showWeeklyOvt() || showWeeklyOvt1() || showWeeklyOvt2()">
													<b-col cols="12">
														<fieldset class="scheduler-border border-groove-blue-streak w-100 pb-3">
															<legend class="scheduler-border text-color-blue-streak">
																{{ FormMSG(390, 'Weekly Overtime') }}
															</legend>
															<b-row>
																<b-col sm="4" v-if="showWeeklyOvt()">
																	<b-form-group
																		style="font-weight: bold"
																		:label="FormMSG(11, 'Overtime:')"
																		label-for="ovt"
																		:label-cols="0"
																	>
																		<b-form-input
																			:disabled="true"
																			v-model="curTs.weeklyTotMinOvtStr"
																			type="text"
																			id="ovt"
																		></b-form-input>
																	</b-form-group>
																</b-col>
																<b-col sm="4" v-if="showWeeklyOvt1()">
																	<b-form-group
																		style="font-weight: bold"
																		:label="FormMSG(121, 'Overtime 1:')"
																		label-for="ovt1"
																		:label-cols="0"
																	>
																		<b-form-input
																			:disabled="true"
																			v-model="curTs.weeklyTotMinOvt1Str"
																			type="text"
																			id="ovt1"
																		></b-form-input>
																	</b-form-group>
																</b-col>
																<b-col sm="4" v-if="showWeeklyOvt2()">
																	<b-form-group
																		style="font-weight: bold"
																		:label="FormMSG(131, 'Overtime 2:')"
																		label-for="ovt2"
																		:label-cols="0"
																	>
																		<b-form-input
																			:disabled="true"
																			v-model="curTs.weeklyTotMinOvt2Str"
																			type="text"
																			id="ovt2"
																		></b-form-input>
																	</b-form-group>
																</b-col>
															</b-row>
														</fieldset>
													</b-col>
												</b-row>
											</b-row>
											<b-row v-if="showTransport() || showNightHours() || showTooEarlyHours()">
												<b-col sm="4">
													<b-form-group
														style="font-weight: bold"
														v-if="showTransport()"
														:label="FormMSG(100, 'Transport:')"
														label-for="transport"
														:label-cols="0"
													>
														<b-form-input :disabled="true" v-model="curTs.hhmmTransport" type="text" id="transport"></b-form-input>
													</b-form-group>
												</b-col>
												<b-col sm="4">
													<b-form-group
														style="font-weight: bold"
														v-if="showNightHours()"
														:label="FormMSG(12, 'Night:')"
														label-for="night"
														:label-cols="0"
													>
														<b-form-input :disabled="true" v-model="curTs.hhmmNight" type="text" id="night"></b-form-input>
													</b-form-group>
												</b-col>
												<b-col sm="4">
													<b-form-group
														style="font-weight: bold"
														v-if="showTooEarlyHours()"
														:label="FormMSG(13, 'Anticipated:')"
														label-for="antic"
														:label-cols="0"
													>
														<b-form-input :disabled="true" v-model="curTs.hhmmTooEarly" type="text" id="antic"></b-form-input>
													</b-form-group>
												</b-col>
											</b-row>
											<b-row>
												<b-col sm="4" v-if="showPreCall()">
													<b-form-group :label="FormMSG(58, 'PreCall:')" label-for="preCall">
														<b-form-input :disabled="true" :value="`00:00`" type="text" id="preCall" />
													</b-form-group>
												</b-col>
												<b-col sm="4" v-if="showLunchPerDiem() || showHotelPerDiem() || showDinnerPerDiem() || showAbroadPerDiem()">
													<b-form-group style="font-weight: bold">
														<label for="diem" class="d-flex align-items-center">
															<div>{{ FormMSG(101, 'Per Diem:') }}</div>
															<div id="tooltip-target-1" class="cursor-pointer ml-1" v-if="curTs.weeklyTotPerDiem > 0">
																<info :size="22" color="#225cbd" />
															</div>
															<b-tooltip target="tooltip-target-1" triggers="hover" v-if="curTs.weeklyTotPerDiem > 0">
																<div v-if="curTs.weeklyHotelPerDiem > 0">
																	<b>{{ FormMSG(103, 'Hotel: ') }}</b>
																	{{ this.displayCurrency(this.curTs.weeklyHotelPerDiem) }}
																</div>
																<div v-if="curTs.weeklyLunchPerDiem > 0">
																	<b>{{ FormMSG(104, 'Lunch: ') }}</b>
																	{{ this.displayCurrency(this.curTs.weeklyLunchPerDiem) }}
																</div>
																<div v-if="curTs.weeklyDinnerPerDiem > 0">
																	<b>{{ FormMSG(471, 'Dinner: ') }}</b>
																	{{ this.displayCurrency(this.curTs.weeklyDinnerPerDiem) }}
																</div>
																<div v-if="curTs.weeklyAbroadPerDiem > 0">
																	<b>{{ FormMSG(472, 'Abroad: ') }}</b>
																	{{ this.displayCurrency(this.curTs.weeklyAbroadPerDiem) }}
																</div>
															</b-tooltip>
														</label>
														<b-form-input :disabled="true" :value="displayCurrency(curTs.weeklyTotPerDiem)" type="text" id="diem" />
													</b-form-group>
												</b-col>
												<b-col sm="4">
													<b-form-group style="font-weight: bold">
														<label for="allowances" class="d-flex align-items-center">
															<div>{{ FormMSG(102, 'Allowances:') }}</div>
															<div
																v-if="curTs.dailyTotAllowances > 0 || curTs.weeklyTotAllowances > 0"
																id="tooltip-target-2"
																class="cursor-pointer ml-1"
															>
																<info :size="22" color="#225cbd" />
															</div>
															<b-tooltip
																target="tooltip-target-2"
																v-if="curTs.dailyTotAllowances > 0 || curTs.weeklyTotAllowances > 0"
																triggers="hover"
															>
																<div v-if="curTs.weeklyCarAllowance > 0">
																	<b>{{ FormMSG(105, 'Car: ') }}</b>
																	{{ displayCurrency(curTs.weeklyCarAllowance) }}
																</div>
																<div v-if="curTs.weeklyComputerAllowance > 0">
																	<b>{{ FormMSG(106, 'Computer: ') }}</b>
																	{{ displayCurrency(curTs.weeklyComputerAllowance) }}
																</div>
																<div v-if="curTs.weeklyPhoneAllowance > 0">
																	<b>{{ FormMSG(107, 'Phone: ') }}</b>
																	{{ displayCurrency(curTs.weeklyPhoneAllowance) }}
																</div>
																<div v-if="curTs.weeklyProductionFeeAllowance > 0">
																	<b>{{ FormMSG(108, 'Production fee: ') }}</b>
																	{{ displayCurrency(curTs.weeklyProductionFeeAllowance) }}
																</div>
																<div v-if="curTs.weeklyBoxKitAllowance > 0">
																	<b>{{ FormMSG(109, 'Box kit: ') }}</b>
																	{{ displayCurrency(curTs.weeklyBoxKitAllowance) }}
																</div>
																<div v-if="curTs.dailyTotAllowances > 0">
																	<b>{{ FormMSG(401, 'Total Daily: ') }}</b>
																	{{ displayCurrency(curTs.dailyTotAllowances) }}
																</div>
															</b-tooltip>
														</label>
														<b-form-input
															:disabled="true"
															:value="this.displayCurrency(curTs.dailyTotAllowances + curTs.weeklyTotAllowances)"
															type="text"
															id="allowances"
														></b-form-input>
													</b-form-group>
												</b-col>
											</b-row>
											<b-row>
												<b-col sm="12">
													<b-form-group
														:label="FormMSG(16, 'Comment:')"
														v-if="curTs.comment.length > 0"
														label-for="comment"
														:label-cols="0"
													>
														<b-form-textarea :disabled="true" id="comment" v-model="curTs.comment" rows="2"></b-form-textarea>
													</b-form-group>
												</b-col>
											</b-row>
										</div>
										<div class="card card-border-blue-light mt-3">
											<b-form-checkbox v-if="canSeeSalary" id="showGridDetails" v-model="showGridDetails" size="lg" class="pj-cb pb-1">
												{{ FormMSG(1224, 'Show salary details') }}
											</b-form-checkbox>
											<!--  <H4>  DAYS </H4>  -->
											<b-row v-if="this.TsDays.length > 0">
												<b-col cols="12" xl="12">
													<CardListBuilder
														v-if="$screen.width < 992"
														style-mode="table"
														:items="TsDays"
														:fields="tsFieldsMobile"
														:collapsed-fields="tsCollapseFieldsMobile"
														:hide-status="isFilmSingle"
														@row-clicked="rowClicked"
													>
														<template slot="actions" slot-scope="{ item, showCollapseDetails }">
															<b-button variant="primary" size="sm" @click="showCollapseDetails(item)">
																<i class="icon-eye"></i>
															</b-button>
															<b-button
																variant="danger"
																v-if="item.validated != 4 && item.validated != 16 && isProd && !isFilmSingle"
																size="sm"
																@click="rejectTsDay(item.id)"
															>
																<i class="cui-ban"></i>
															</b-button>
														</template>
													</CardListBuilder>
													<b-table
														v-if="$screen.width >= 992"
														:hover="hover"
														responsive="sm"
														:items="TsDays"
														style="text-align: center"
														:fields="tsFields"
														sticky-header="700px"
														:current-page="currentPage"
														@row-dblclicked="rowClicked"
														:per-page="perPage"
														:head-variant="hv"
														bordered
														small
													>
														<template #cell(dayType)="data">
															<span v-html="data.value" />
														</template>
														<template #cell(hhmm)="data">
															<span v-html="data.value"></span>
														</template>
														<template #cell(hhmmOvt)="data">
															<span v-html="data.value"></span>
														</template>
														<template #cell(hhmmOvt1)="data">
															<span v-html="data.value"></span>
														</template>
														<template #cell(hhmmOvt2)="data">
															<span v-html="data.value"></span>
														</template>
														<template #cell(hhmmTransport)="data">
															<span v-html="data.value"></span>
														</template>
														<template #cell(hhmmNight)="data">
															<span v-html="data.value"></span>
														</template>
														<template #cell(hhmmTooEarly)="data">
															<span v-html="data.value"></span>
														</template>
														<template #cell(remark)="data">
															<Button
																v-if="data.item.remark"
																v-tooltip.left.focus="data.item.remark"
																class="p-0 p-button-text p-button-info"
															>
																<MessageCircle />
															</Button>
														</template>
														<template
															#cell(lunch)="data"
															v-if="showLunchPerDiem() || showHotelPerDiem() || showDinnerPerDiem() || showAbroadPerDiem()"
														>
															<div class="d-flex justify-content-end align-items-center">
																<button
																	v-if="data.item.totPerDiem > 0"
																	class="btn-transparent text-color-rhapsody-in-blue"
																	v-b-tooltip.hover.left.html="tooltipContentPerIdem(data.item)"
																>
																	<component :is="getIconLucide('Info')" color="#225CBD" :size="20" />
																</button>
																<div class="adjust-icon-ts-tsd">{{ displayCurrency(data.item.totPerDiem) }}</div>
															</div>
														</template>
														<template #cell(hotel)="data">
															<div class="d-flex justify-content-end align-items-center">
																<button
																	v-if="data.item.totAllowances > 0"
																	class="btn-transparent text-color-rhapsody-in-blue"
																	v-b-tooltip.hover.left.html="tooltipContentAllowance(data.item)"
																>
																	<component :is="getIconLucide('Info')" color="#225CBD" :size="20" />
																</button>
																<div class="adjust-icon-ts-tsd">{{ displayCurrency(data.item.totAllowances) }}</div>
															</div>
														</template>
														<template v-slot:cell(validatedStatus)="data">
															<div class="wrap-status">
																<div :class="`status ${data.item.statusClass}`" style="font-size: 0.7rem">
																	{{ data.item.validatedStatus }}
																</div>
															</div>
														</template>
														<template #cell(salary)="data">
															<div class="d-flex flex-row align-items-center justify-content-end w-100">
																<salary-log :item="data.item" />
																<div style="margin-top: 2px">{{ rendCurrency(data.item.salary) }}</div>
															</div>
														</template>
													</b-table>
												</b-col>
											</b-row>
											<b-row v-if="!isFilmSingle && curTs.validated !== 0 && curTs.validated !== 4 && curTs.validated !== 16">
												<b-col cols="4"></b-col>
												<b-col cols="8">
													<b-row class="pull-right">
														<b-col cols="12">
															<div v-if="$screen.width >= 992" class="w-100 d-flex justify-content-center align-items-center">
																<b-button
																	@click="downloadXls"
																	size="md"
																	variant="outline-primary"
																	class="d-flex justify-content-center align-items-center mr-3"
																	:disabled="isLoadingDownload"
																>
																	<b-spinner v-show="isLoadingDownload" class="mr-2" small></b-spinner>
																	<component
																		:is="getLucideIcon(ICONS.DOWNLOAD.name)"
																		:size="20"
																		class="mr-2"
																		v-if="!isLoadingDownload"
																	/>
																	<div style="margin-top: 1px">{{ this.FormMSG(221, 'Download this Timesheet') }}</div>
																</b-button>
																<b-button
																	@click="sendXlsByEmail"
																	size="md"
																	variant="outline-primary"
																	class="d-flex justify-content-center align-items-center"
																	:disabled="isLoadingSendMail"
																>
																	<b-spinner v-show="isLoadingSendMail" class="mr-2" small></b-spinner>
																	<component
																		:is="getLucideIcon(ICONS.SEND.name)"
																		:size="20"
																		class="mr-2"
																		v-if="!isLoadingSendMail"
																	/>
																	<div style="margin-top: 1px">{{ this.FormMSG(21, 'Send me this Timesheet by Email') }}</div>
																</b-button>
															</div>
															<div v-if="$screen.width < 992">
																<b-button @click="downloadXls" size="md" variant="primary" class="mr-3" id="btn-download">
																	<i class="cui-cloud-download"></i>
																</b-button>
																<b-tooltip target="btn-download" placement="top">
																	{{ this.FormMSG(221, 'Download this Timesheet') }}
																</b-tooltip>
																<b-button @click="sendXlsByEmail" size="md" variant="primary" id="btn-envelope-closed">
																	<i class="cui-envelope-closed"></i>
																</b-button>
																<b-tooltip target="btn-envelope-closed" placement="top">
																	{{ this.FormMSG(21, 'Send me this Timesheet by Email') }}
																</b-tooltip>
															</div>
														</b-col>
													</b-row>
												</b-col>
											</b-row>
											<b-row>
												<b-col cols="12">
													<div class="pull-right">
														<share-information
															v-if="isFilmSingle"
															:btn-label="FormMSG(45, 'Share invoice')"
															:title-modal="FormMSG(46, 'Share the invoice expense to :')"
															:current-timesheet="curTs"
															:is-send-invoice="true"
															send-a-copy
															name-modal="modal-share-invoice-timesheet"
															icon="fa fa-file-text"
															variant-btn="warning"
															style="margin-right: 5px"
															:isDisabled="!isFilmSinglePaying"
														/>
														<share-information
															v-if="isFilmSingle"
															:btn-label="FormMSG(169, 'Share')"
															:title-modal="FormMSG(170, 'Send this timesheet to :')"
															:current-timesheet="curTs"
															send-a-copy
															name-modal="modal-share-timesheet"
														/>
													</div>
												</b-col>
											</b-row>
										</div>
									</b-col>
								</b-row>
								<b-modal
									header-class="header-class-modal-doc-package"
									:title="FormMSG(22, 'Success!')"
									class="modal-success"
									v-model="successModal"
									@ok="successModal = false"
									ok-variant="success"
									ok-only
								>
									{{ this.FormMSG(23, 'The timesheet has been sent to your Email address') }}
								</b-modal>
								<b-modal
									header-class="header-class-modal-doc-package"
									:title="FormMSG(24, 'Refused Day result')"
									class="modal-danger"
									v-model="dayResultComment"
									@ok="dayResultComment = false"
									ok-variant="danger"
									ok-only
								>
									<b-row>
										<b-col cols="12" sm="12">
											<b-form-group :label="FormMSG(25, 'Comment:')" label-for="comment" :label-cols="2">
												<b-form-textarea :disabled="true" id="comment" v-model="TsDayComment" rows="2"></b-form-textarea>
											</b-form-group>
										</b-col>
									</b-row>
								</b-modal>
								<b-modal
									header-class="header-class-modal-doc-package"
									:title="FormMSG(339, 'Refuse this day')"
									class="modal-danger"
									v-model="successModalRejectedTsDay"
									@ok="finaliseTsDayReject"
									ok-variant="danger"
									ok-only
								>
									<b-form-group :label="FormMSG(337, 'Enter your comment:')" label-for="comment" :label-cols="4">
										<b-form-textarea
											id="comment"
											v-model="rejectComment"
											:placeholder="this.FormMSG(338, 'Enter your remarks...')"
											rows="3"
										></b-form-textarea>
									</b-form-group>
								</b-modal>
							</b-card-text>
						</b-card>
					</b-col>
				</b-row>
				<script-export-modal
					v-model="isScriptTimeSheetReportOpen"
					:items="scripts"
					:custom="customReport"
					export-type="ts"
					@script-export-modal:loading="handleLoading"
					@script-export-modal:closed="timeSheetRerpotOnClosed"
				/>
			</div>
		</transition>
	</errorContainer>
</template>

<script>
// import gql from 'graphql-tag';
import { isNil, formatFileName } from '@/shared/utils';
import { store } from '@/store';
import Loading from 'vue-loading-overlay';
import languageMessages from '@/mixins/languageMessages';
import { rendCurrency, rendKgCo2 } from '~helpers';
import { MessageCircle } from 'lucide-vue';
import Button from 'primevue/button';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import globalMixin from '@/mixins/global.mixin';
import ShareInformation from '@/components/InfoSheet/ShareInformation';
import moment from 'moment';
import CurrencySvg from '@/components/Svg/Currency';
import DetailsTimesheetMobile from '@/components/MyTimesheetMobile/DetailsTimesheetMobile';
import { classStatus } from '@/shared/global-status';
import { Info, ArrowLeft } from 'lucide-vue';
import * as icons from 'lucide-vue';
import { getTimesheetDetail, rejectTsDay } from '@/cruds/timesheet.crud';
import { getScripts } from '@/cruds/script.crud';
import { timeSheetReport } from '@/cruds/export.crud';
import ScriptExportModal from '@/components/Export/ScriptExportModal';
import _ from 'lodash';
import { FILENAME_REPORT, OUTPUT_FORMAT_EXPORT } from '@/shared/constants';
import { getFileExtension } from '@/shared/helpers';
import SalaryLog from '@/components/Timesheet/SalaryLog';

export default {
	name: 'TimesheetDetails',
	components: { Loading, ShareInformation, DetailsTimesheetMobile, Button, MessageCircle, Info, CurrencySvg, ArrowLeft, ScriptExportModal, SalaryLog },
	mixins: [languageMessages, isSingleProjectMixin, globalMixin],
	props: {
		caption1: {
			type: String,
			default: ''
		},
		hover: {
			type: Boolean,
			default: true
		},
		striped: {
			type: Boolean,
			default: true
		},
		bordered: {
			type: Boolean,
			default: true
		},
		small: {
			type: Boolean,
			default: false
		},
		fixed: {
			type: Boolean,
			default: false
		},
		intialDate: ['2019-09-10']
	},
	data() {
		return {
			downloadLink: null,
			hv: 'dark',
			Validated: 0,
			showGridDetails: false,
			erreur: {},
			totalDiem: 0,
			TsDayComment: '',
			rejectComment: '',
			successModalRejectedTsDay: false,
			curTsDayRejected: 0,
			curTs: {
				id: 0,
				strDate: '',
				endDate: '',
				hours: '',
				minutes: '',
				hoursOvertime: '',
				minutesOvertime: '',
				hoursOvertime1: '',
				minutesOvertime1: '',
				hoursOvertime2: '',
				minutesOvertime2: '',
				hoursTransportTimePaid: '',
				minutesTransportTimePaid: '',
				hoursNight: '',
				minutesNight: '',
				hoursTooEarly: '',
				minutesTooEarly: '',
				salary: 0,
				validated: 0,
				validatedStatus: 'Not Submited',
				hhmm: '',
				hhmmOvt: '',
				hhmmOvt1: '',
				hhmmOvt2: '',
				hhmmTransport: '',
				hhmmNight: '',
				hhmmTooEarly: '',
				comment: '',
				user: {}
			},
			curMonthStr: '',
			TsDays: [],
			currentPage: 1,
			perPage: 0,
			warning: '',
			successModal: false,
			dayResultComment: false,
			filter: '',
			isLoading: false,
			timeZoneStr: '',
			timeZoneHourDif: 0,
			curDay: {
				id: 0,
				date: '',
				strTime: 'T08:00:00Z',
				endTime: 'T18:00:00Z',
				totMin: 0,
				lunchStrTime: 'T00:00:00Z',
				lunchEndTime: 'T00:00:00Z',
				lunch: false,
				hotel: false,
				stageDayUsed: false,
				validated: 0,
				validatedStatus: 'Not Submited',
				comment: ''
			},
			contract: {
				defaultDayType: 0,
				hotelPerDiem: 0,
				lunchPerDiem: 0,
				dinnerPerDiem: 0,
				abroadPerDiem: 0
			},
			myContracts: [],
			selectedColor: 'primary',
			selectMode: 'single',
			isScriptTimeSheetReportOpen: false,
			scriptTypeId: 10,
			scripts: [],
			isLoadingSendMail: false,
			customReport: null,
			isLoadingDownload: false,
			rendCurrency
		};
	},
	computed: {
		setAllowTimesheetEntryOutsideContract() {
			return store.getCurrentProjectConfig().allowTSEntryOutsideContract;
		},
		returnCurrency(value) {
			return rendCurrency(value);
		},
		userId() {
			return store.state.myProfile.id;
		},
		isProd() {
			return store.isProd();
		},
		tsFields() {
			return this.fliedsBase;
		},
		canSeeSalary() {
			return !store.getCurrentProjectConfig().hideSalaryInfo;
		},
		tsFieldsMobile() {
			return [
				{
					key: 'date',
					label: this.FormMSG(31, 'Date'),
					formatter: (value) => {
						return new moment(value).utc().format('DD/MM/YYYY');
					},
					sortable: false
				},
				{
					key: 'strTime',
					label: this.FormMSG(32, 'Start'),
					formatter: (value) => {
						return value.split('T')[1].substring(0, 5);
					},
					sortable: false,
					class: 'text-center'
				},
				{
					key: 'endTime',
					label: this.FormMSG(33, 'End'),
					formatter: (v, z, root) => {
						const time = root.endTime.split('T')[1].substring(0, 5);
						return root.endTime.substring(8, 10) == root.strTime.substring(8, 10) ? time : time + ' (' + root.endTime.substring(8, 10) + ')';
					},
					sortable: false,
					class: 'text-center'
				},
				{
					key: 'salary',
					label: this.FormMSG(4250, 'Salary'),
					formatter: (value) => rendCurrency(value),
					sortable: false,
					class: 'text-right'
				}
			];
		},
		tsCollapseFieldsMobile() {
			const keys = ['date', 'strTime', 'endTime', 'salary', 'validatedStatus'];
			return this.fliedsBase.filter((t) => !keys.includes(t.key));
		},
		fliedsBase() {
			let flds = [];
			const statusFld = {
				key: 'validatedStatus',
				label: this.FormMSG(30, 'Status'),
				sortable: false
			};
			// console.log("isfilmsingle:",this.isFilmSingle)
			if (!this.isFilmSingle) {
				flds.push(statusFld);
			}
			const fldsBase = [
				{
					key: 'date',
					label: this.FormMSG(31, 'Date'),
					formatter: (value) => {
						return new moment(value).utc().format('DD/MM/YYYY');
					},
					sortable: false
				},
				{
					key: 'strTime',
					label: this.FormMSG(32, 'Start'),
					formatter: (value) => {
						return value.split('T')[1].substring(0, 5);
					},
					sortable: false
				},
				{
					key: 'dayType',
					label: this.FormMSG(49, 'Type'),
					formatter: (value, key, item) => {
						return this.getFieldValueByShowGridDetails(this.showGridDetails, value, item.salaryBase);
					},
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'endTime',
					label: this.FormMSG(33, 'End'),
					formatter: (v, z, root) => {
						const time = root.endTime.split('T')[1].substring(0, 5);
						return root.endTime.substring(8, 10) == root.strTime.substring(8, 10) ? time : time + ' (' + root.endTime.substring(8, 10) + ')';
					},
					sortable: false
				},
				{
					key: 'hhmm',
					label: this.FormMSG(36, 'Hours'),
					formatter: (v, z, root) => {
						let separator = this.$screen.width < 992 ? ' | ' : '<br>';
						return !this.showGridDetails ? root.hhmm : root.hhmm + separator + rendCurrency(root.salaryBase);
					},
					sortable: false
				}
			];
			flds = flds.concat(fldsBase);

			const ovtFld = {
				key: 'hhmmOvt',
				label: this.FormMSG(37, 'Ovt'),
				formatter: (v, z, root) => {
					let separator = this.$screen.width < 992 ? ' | ' : '<br>';
					return !this.showGridDetails ? root.hhmmOvt : root.hhmmOvt + separator + rendCurrency(root.salaryOvertime);
				}
			};
			if (this.showOvt()) {
				flds.push(ovtFld);
			}

			const ovt1Fld = {
				key: 'hhmmOvt1',
				label: this.FormMSG(137, 'Ovt1'),
				formatter: (v, z, root) => {
					let separator = this.$screen.width < 992 ? ' | ' : '<br>';
					return !this.showGridDetails ? root.hhmmOvt1 : root.hhmmOvt1 + separator + rendCurrency(root.salaryOvertime1);
				}
			};
			if (this.showOvt1()) {
				flds.push(ovt1Fld);
			}

			const ovt2Fld = {
				key: 'hhmmOvt2',
				label: this.FormMSG(138, 'Ovt2'),
				formatter: (v, z, root) => {
					let separator = this.$screen.width < 992 ? ' | ' : '<br>';
					return !this.showGridDetails ? root.hhmmOvt2 : root.hhmmOvt2 + separator + rendCurrency(root.salaryOvertime2);
				}
			};
			if (this.showOvt2()) {
				flds.push(ovt2Fld);
			}

			const transportFld = {
				key: 'hhmmTransport',
				label: this.FormMSG(139, 'Transport'),
				formatter: (v, z, root) => {
					let separator = this.$screen.width < 992 ? ' | ' : '<br>';
					return !this.showGridDetails ? root.hhmmTransport : root.hhmmTransport + separator + rendCurrency(root.salaryTransport);
				}
			};
			if (this.showTransport()) {
				flds.push(transportFld);
			}

			const nightFld = {
				key: 'hhmmNight',
				label: this.FormMSG(62, 'Night'),
				formatter: (v, z, root) => {
					let separator = this.$screen.width < 992 ? ' | ' : '<br>';
					return !this.showGridDetails ? root.hhmmNight : root.hhmmNight + separator + rendCurrency(root.salaryHourNight);
				}
			};
			if (this.showNightHours()) {
				flds.push(nightFld);
			}

			const tooEarlyHoursFld = {
				key: 'hhmmTooEarly',
				label: this.FormMSG(38, 'Anticipated'),
				formatter: (v, z, root) => {
					let separator = this.$screen.width < 992 ? ' | ' : '<br>';
					return !this.showGridDetails ? root.hhmmTooEarly : root.hhmmTooEarly + separator + rendCurrency(root.salaryHourTooEarly);
				}
			};
			if (this.showTooEarlyHours()) {
				flds.push(tooEarlyHoursFld);
			}

			const beforeCallTime = {
				key: 'beforeCallTime',
				label: this.FormMSG(57, 'Precall'),
				sortable: false
			};
			if (this.showPreCall()) {
				flds.push(beforeCallTime);
			}

			const lunchflds = [
				{
					key: 'lunchStrTime',
					label: this.FormMSG(34, 'Lunch start'),
					formatter: (v, z, root) => {
						const time = root.lunchStrTime.split('T')[1].substring(0, 5);
						return root.lunchEndTime.substring(11, 15) == root.lunchStrTime.substring(11, 15)
							? ''
							: root.date.substring(8, 10) == root.lunchStrTime.substring(8, 10)
							? time
							: time + ' (' + root.lunchStrTime.substring(8, 10) + ')';
					},
					sortable: false
				},
				{
					key: 'lunchEndTime',
					label: this.FormMSG(35, 'Lunch end'),
					formatter: (v, z, root) => {
						const time = root.lunchEndTime.split('T')[1].substring(0, 5);
						//console.log("in formatter:",root)
						return root.lunchEndTime.substring(11, 15) == root.lunchStrTime.substring(11, 15)
							? ''
							: root.date.substring(8, 10) == root.lunchEndTime.substring(8, 10)
							? time
							: time + ' (' + root.lunchEndTime.substring(8, 10) + ')';
					},
					sortable: false
				}
			];
			if (this.showLunch()) {
				flds = flds.concat(lunchflds);
			}
			const lunchPd = {
				key: 'lunch',
				label: this.FormMSG(399, 'Per Diem'),
				sortable: false,
				class: 'text-center'
			};
			if (this.showLunchPerDiem() || this.showHotelPerDiem() || this.showDinnerPerDiem() || this.showAbroadPerDiem()) {
				flds.push(lunchPd);
			}
			const HotelPd = {
				key: 'hotel',
				label: this.FormMSG(400, 'Allowances'),
				sortable: false,
				class: 'text-center'
			};
			// if (this.showHotelPerDiem()) {
			flds.push(HotelPd);
			// }
			const flds1 = [
				{
					key: 'dayRate',
					label: this.FormMSG(41, 'Rate'),
					formatter: (value) => {
						return value + ' %';
					},
					sortable: false
				},
				{
					key: 'kgCoTwo',
					label: 'CO2',
					formatter: (v) => rendKgCo2(v),
					sortable: true
				},
				{
					key: 'remark',
					label: this.FormMSG(5001, 'Comment'),
					sortable: false,
					class: 'text-center'
				}
			];
			let salary = {
				key: 'salary',
				label: this.FormMSG(25503, 'Salary'),
				formatter: (value) => {
					return rendCurrency(value);
				},
				class: 'text-right',
				sortable: false
			};

			if (!store.getCurrentProjectConfig().hideSalaryInfo || store.canViewGlobalBudget()) {
				flds1.push(salary);
			}

			flds = flds.concat(flds1);

			return flds;
		},
		caption2() {
			if (isNil(this.curTs.user)) return '';

			const strFrom = moment(this.curTs.strDate).format('DD/MM/YYYY');

			const strTo = moment(this.curTs.endDate).format('DD/MM/YYYY');
			// const str = this.curTs.user.name + ' ' + this.curTs.user.firstName + ' ' + strFrom + strTo;
			const str = strFrom + ' - ' + strTo;

			return str;
		},
		styleObject: function () {
			var myObj = {
				color: 'black',
				fontSize: '14px'
			};
			if (this.curTs.validated == 1) {
				myObj.color = 'blue';
			} else if (this.curTs.validated == 2) {
				myObj.color = 'yellow';
			} else if (this.curTs.validated == 8) {
				myObj.color = 'green';
			} else if (this.curTs.validated == 4 || this.curTs.validated == 16) {
				myObj.color = 'red';
			}
			return myObj;
		}
	},
	methods: {
		encodedBy(data) {
			if (data !== null) {
				let firstName = data.encoder !== undefined ? data.encoder.firstName : '';
				let name = data.encoder !== undefined ? data.encoder.name : '';
				return `${firstName} ${name}`;
			}
		},
		rendAllowanceType(type) {
			if (type == 0) {
				return this.FormMSG(112, 'D');
			} else if (type == 1) {
				return this.FormMSG(113, 'W');
			} else if (type == 2) {
				return this.FormMSG(111, 'H');
			}
		},
		handleClickBack() {
			if (this.$route.query.budgetSalaries) {
				this.$router.push({ path: `/budget?activeTabBudgetSalaries=1&filterSearch=${this.$route.query.filterSearch}` });
			} else {
				let href = window.location.href.split('/')[4];
				let home = window.location.href.split`#/`[1].split`/`[0];
				if (href === 'timesheetsEntryCrew') {
					let home = window.location.href.split`#/`[1].split`/`[0];
					const path = `/${home}`;
					return this.$router.push({ path: path + `?activeSecondTab=1&activeFilter=${this.$route.query.activeFilter}` });
				}
				// console.log(this.$route.query);
				if (this.$route.query.previousActiveTab) {
					let home = window.location.href.split`#/`[1].split`/`[0];
					const path = `/${home}`;
					return this.$router.push({
						path: path,
						query: {
							tabs: `activeTabs-${this.$route.query.previousActiveTab}`
						}
					});
				}

				return this.$router.go(-1);
			}
		},
		setContractForDay(date) {
			//console.log("in set contractForDay this.myContracts:",this.myContracts);
			for (let i = 0; i < this.myContracts.length; i++) {
				//console.log("in set contractForDay date:",date);
				//console.log("in set contractForDay date:",this.myContracts[i].startDate);
				if (date >= this.myContracts[i].startDate.substring(0, 10) && date <= this.myContracts[i].endDate.substring(0, 10)) {
					//console.log("in set contractForDay contract found:",this.myContracts[i]);
					this.contract = this.myContracts[i];
					return;
				}
			}
			// console.log("in set contractForDay contract found:",this.myContracts[0]);
			if (!isNil(this.myContracts[0])) {
				this.contract = this.myContracts[0];
			}
		},
		showNightHours() {
			return store.getCurrentProjectConfig().useNightHours;
		},
		showTooEarlyHours() {
			return store.getCurrentProjectConfig().useTooEarlyHours;
		},
		showOvt() {
			return store.getCurrentProjectConfig().useOvertime;
		},
		showOvt1() {
			return store.getCurrentProjectConfig().useOvt1;
		},
		showOvt2() {
			return store.getCurrentProjectConfig().useOvt2;
		},
		showTransport() {
			//console.log("in show transport:",store.getCurrentProjectConfig());
			return store.getCurrentProjectConfig().useHomeToWorkTime;
		},
		showLunch() {
			return store.getCurrentProjectConfig().useLunchTime;
		},
		showLunchPerDiem() {
			return store.getCurrentProjectConfig().useLunchPerDiem && !this.curDay.stageDayUsed;
		},
		showHotelPerDiem() {
			return store.getCurrentProjectConfig().useHotelPerDiem;
		},
		displayCurrency(value) {
			return rendCurrency(value);
		},
		displayKgCoTwo(value) {
			return rendKgCo2(value);
		},
		rowClicked(item) {
			const currentRouteName = this.$route.name;
			if (currentRouteName !== 'Budget Timesheet Details') {
				const { budgetSalaries } = this.$route.query;
				if (isNil(budgetSalaries)) {
					this.$router.push({ path: '/timesheet', query: { date: item.date, parentTab: this.$route.query.previousActiveTab } });
				}
			}
		},
		getCurrentDate() {
			var currentDateWithFormat = new Date().toJSON().slice(0, 10);
			return currentDateWithFormat;
		},
		firstTimeInitialisation() {
			//console.log("in firstTimeInitialisation");
			this.setTimeZoneStr();
			// this.curTs = store.getCurTimesheet();
			this.curDay.date = this.getCurrentDate();
			this.curMonthStr = this.curDay.date.substring(0, 7);
		},
		updateTimeToTZ(timeString) {
			this.setTimeZoneStr();
			if (timeString.length == 0) {
				return timeString;
			}
			// check if already converted
			//  console.log("in updateTimeToTZ  RECEIVING:",timeString );
			//console.log("substring 9=",timeString.substring(9) );
			//console.log("in updateTimeToTZ timeZoneStr=",this.timeZoneStr);

			if (timeString.substring(9) != this.timeZoneStr) {
				// needs conversion
				//console.log("in updateTimeToTZ inval=",timeString)
				// extract time value
				var timStr = timeString.substring(1, 3);
				var hGmt = parseInt(timStr, 10);
				var h = (hGmt + this.timeZoneHourDif) % 24;
				if (h > 9) {
					var retval = 'T' + h + timeString.substring(3);
				} else {
					var retval = 'T0' + h + timeString.substring(3);
				}
				//    console.log("in updateTimeToTZ retval=",retval);
				retval = retval.substring(0, 9) + this.timeZoneStr;
				//    console.log("in updateTimeToTZ retval new TZ=",retval);
				return retval;
			} else {
				// does not need conversion
				var retval = timeString;
				//    console.log("in updateTimeToTZ retval kept existing conversion=",retval);
				return retval;
			}
		},
		updateTZdataEach(timeData) {
			this.setContractForDay(timeData.date);
			let totDailyMin = parseInt(timeData.minutesOvertime) + parseInt(timeData.minutesOvertime1) + parseInt(timeData.minutesOvertime2);
			let totDailyHrs = parseInt(timeData.hoursOvertime) + parseInt(timeData.hoursOvertime1) + parseInt(timeData.hoursOvertime2);
			// setting row color
			timeData.validatedStatus = this.validatedText(timeData.validated);
			// https://bootstrap-vue.js.org/docs/reference/color-variants
			timeData._rowVariant = ''; //this.validatedColor(timeData.validated);
			timeData.validatedClass = this.validatedColor(timeData.validated);
			timeData.statusClass = classStatus(timeData.validated);
			timeData.strTime = moment(timeData.strTime).format();
			timeData.endTime = moment(timeData.endTime).format();
			timeData.lunchStrTime = moment(timeData.lunchStrTime).format();
			timeData.lunchEndTime = moment(timeData.lunchEndTime).format();
			timeData.leftHomeAt = moment(timeData.leftHomeAt).format();
			timeData.returnedHomeAt = moment(timeData.returnedHomeAt).format();
			timeData.hhmm = timeData.hours + ':' + timeData.minutes;
			timeData.hhmmOvt = timeData.hoursOvertime + ':' + timeData.minutesOvertime;
			timeData.hhmmNight = timeData.hoursNight + ':' + timeData.minutesNight;
			timeData.hhmmTooEarly = timeData.hoursTooEarly + ':' + timeData.minutesTooEarly;
			timeData.hhmmOvt1 = timeData.hoursOvertime1 + ':' + timeData.minutesOvertime1;
			timeData.hhmmOvt2 = timeData.hoursOvertime2 + ':' + timeData.minutesOvertime2;
			timeData.tothhmmOvt = totDailyHrs + ':' + totDailyMin;
			timeData.hhmmTransport = timeData.hoursTransportTimePaid + ':' + timeData.minutesTransportTimePaid;
			timeData.hotelPerDiem = timeData.contract.hotelPerDiem;
			timeData.lunchPerDiem = timeData.contract.lunchPerDiem;
			timeData.dinnerPerDiem = timeData.contract.dinnerPerDiem;
			timeData.abroadPerDiem = timeData.contract.abroadPerDiem;
		},
		setTimeZoneStr() {
			// getting timezone
			if (this.timeZoneStr.length > 0) {
				return;
			}
			var d = new Date();
			var n = d.getTimezoneOffset();
			if (n < 0) {
				var hOffset = -n / 60;
				var sign = '+';
			} else {
				var hOffset = n / 60;
				var sign = '-';
			}
			var BASE = '00';
			this.timeZoneStr = hOffset ? sign + BASE.substr(0, 2 - Math.ceil(hOffset / 10)) + hOffset : sign + BASE;
			this.timeZoneStr = this.timeZoneStr + ':00';
			this.timeZoneHourDif = hOffset;
		},
		validatedText(validated) {
			// returns the label text corresponding to the validated value
			var mnu = this.FormMenu(1008);
			var menuMap = new Map();
			for (var i = 0; i < mnu.length; i++) {
				menuMap.set(mnu[i].value, mnu[i].text);
			}
			//console.log("menuMap:",menuMap);
			if (validated == 1) {
				var retval = menuMap.get(1);
			} else if (validated == 2) {
				var retval = menuMap.get(2);
			} else if (validated == 8) {
				var retval = menuMap.get(8);
			} else if (validated == 4) {
				var retval = menuMap.get(4);
			} else if (validated == 16) {
				var retval = menuMap.get(16);
			} else {
				var retval = menuMap.get(0);
			}

			return retval;
		},
		validatedColor(validated) {
			if (validated == 1) {
				var retval = 'primary';
			} else if (validated == 2) {
				var retval = 'warning';
			} else if (validated == 8) {
				var retval = 'success';
			} else if (validated == 4 || validated == 16) {
				var retval = 'danger';
			} else {
				var retval = 'info';
			}
			return retval;
		},
		async reloadData() {
			var TimesheetId = parseInt(this.$route.params.id, 10);

			await getTimesheetDetail(TimesheetId)
				.then((result) => {
					result.forEach(this.updateTZdataEach);
					this.TsDays = result;
					// console.log({TsDays: this.TsDays})
					if (this.TsDays.length > 0) {
						let curTs = this.TsDays[0].timesheet;
						// console.log({curTs})
						curTs.contract = this.TsDays[0].contract;
						this.updateTZdataEach(curTs);
						this.curTs = curTs;
						this.curTs.comment = this.TsDays[0].timesheet.comment;
						this.curTs.user = this.TsDays[0].user;
					}
					//console.log("reloaddata:",this.TsDays);
				})
				.catch((error) => {
					console.log(error);
					this.erreur = error;
				});
		},
		async sendXlsByEmail() {
			this.isLoadingSendMail = true;
			this.warning = '';
			this.scripts = await getScripts(store.getCurrentProjectConfig().licenseID, store.getCurrentProjectConfig().id, this.scriptTypeId, true);
			const id = +this.curTs.id;

			if (this.scripts.length === 0) {
				await this.manageTimesheetReport(id, { id: 0 }, true).then(() => {
					this.createToastForMobile(this.FormMSG(475, 'Success'), this.FormMSG(476, 'The document has been sent to your email address.'));
				});
			} else if (this.scripts.length === 1) {
				await this.manageTimesheetReport(id, { id: this.scripts[0].id }, true).then(() => {
					this.createToastForMobile(this.FormMSG(475, 'Success'), this.FormMSG(476, 'The document has been sent to your email address.'));
				});
			} else {
				this.isScriptTimeSheetReportOpen = true;
				this.customReport = {
					subjectId: id,
					sendEmail: true
				};
			}
		},
		async downloadXls() {
			this.isLoadingDownload = true;
			this.warning = '';
			this.scripts = await getScripts(store.getCurrentProjectConfig().licenseID, store.getCurrentProjectConfig().id, this.scriptTypeId, true);
			const id = +this.curTs.id;

			if (this.scripts.length === 0) {
				await this.manageTimesheetReport(id).finally(() => {
					this.isLoadingDownload = false;
				});
			} else if (this.scripts.length === 1) {
				await this.manageTimesheetReport(id, {
					id: this.scripts[0].id,
					fileName: this.scripts[0].customExportFileName,
					ext: this.getExtensionScript(
						OUTPUT_FORMAT_EXPORT.at(this.scripts[0].outputFileFormat),
						this.scripts[0].customExtension,
						getFileExtension(this.scripts[0].fileName)
					)
				}).finally(() => {
					this.isLoadingDownload = false;
				});
			} else {
				this.isScriptTimeSheetReportOpen = true;
				this.customReport = {
					subjectId: id,
					sendEmail: false
				};
			}
		},
		rejectTsDay(id) {
			this.curTsDayRejected = id;
			this.rejectComment = '';
			this.successModalRejectedTsDay = true;
		},
		async finaliseTsDayReject() {
			this.successModalRejectedTsDay = false;
			var TsDayId = parseInt(this.curTsDayRejected, 10);
			var Comment = this.rejectComment;
			// console.log('in finalize day reject id:', TsDayId)
			// console.log('in finalize day reject comment:', Comment)
			await rejectTsDay(TsDayId, Comment)
				.then(async (result) => {
					await this.reloadData();
					this.rejectComment = '';
					this.curTsDayRejected = 0;
					this.successModalRejected = true;
				})
				.catch((error) => {
					console.log(error);
					this.erreur = error;
				});
		},
		classStatus(validated) {
			return classStatus(validated);
		},
		getIconLucide(name) {
			return icons[name];
		},
		showDinnerPerDiem() {
			return store.getCurrentProjectConfig().useDinnerPerDiem;
		},
		showAbroadPerDiem() {
			return store.getCurrentProjectConfig().useAbroadPerDiem;
		},
		tooltipContentPerIdem(item) {
			let content = [];
			if (item.hotel && item.hotelPerDiem > 0) {
				content.push(this.FormMSG(103, 'Hotel: ') + this.displayCurrency(item.hotelPerDiem));
			}

			if (item.lunch && item.lunchPerDiem > 0) {
				content.push(this.FormMSG(104, 'Lunch: ') + this.displayCurrency(item.lunchPerDiem));
			}

			if (item.useDinner && item.dinnerPerDiem > 0) {
				content.push(this.FormMSG(473, 'Dinner: ') + this.displayCurrency(item.dinnerPerDiem));
			}

			if (item.useAbroad && item.abroadPerDiem > 0) {
				content.push(this.FormMSG(474, 'Abroad: ') + this.displayCurrency(item.abroadPerDiem));
			}

			return content.join('<br />');
		},
		tooltipContentAllowance(item) {
			let content = [];
			if (item.carAllowance > 0) {
				content.push(
					this.FormMSG(110, 'Car ') + '(' + this.rendAllowanceType(item.contract.carAllowanceRate) + '): ' + this.displayCurrency(item.carAllowance)
				);
			}

			if (item.computerAllowance > 0) {
				content.push(
					this.FormMSG(114, 'Computer ') +
						'(' +
						this.rendAllowanceType(item.contract.computerAllowanceRate) +
						'): ' +
						this.displayCurrency(item.computerAllowance)
				);
			}

			if (item.phoneAllowance > 0) {
				content.push(
					this.FormMSG(115, 'Phone ') +
						'(' +
						this.rendAllowanceType(item.contract.phoneAllowanceRate) +
						'): ' +
						this.displayCurrency(item.phoneAllowance)
				);
			}

			if (item.productionFeeAllowance > 0) {
				content.push(
					this.FormMSG(116, 'Production fee ') +
						'(' +
						this.rendAllowanceType(item.contract.productionFeeAllowanceRate) +
						'): ' +
						this.displayCurrency(item.productionFeeAllowance)
				);
			}

			if (item.boxKitAllowance > 0) {
				content.push(
					this.FormMSG(117, 'Box kit ') +
						'(' +
						this.rendAllowanceType(item.contract.boxKitAllowanceRate) +
						'): ' +
						this.displayCurrency(item.boxKitAllowance)
				);
			}

			if (item.mileageToSetAllowance > 0) {
				content.push(`${this.FormMSG(896, 'Mileage to set')}: ${this.displayCurrency(item.mileageToSetAllowance)}`);
			}

			return content.join('<br/>');
		},
		showWeeklyOvt() {
			return store.getCurrentProjectConfig().useWeeklyOvt;
		},
		showWeeklyOvt1() {
			return store.getCurrentProjectConfig().useWeeklyOvt1;
		},
		showWeeklyOvt2() {
			return store.getCurrentProjectConfig().useWeeklyOvt2;
		},
		showPreCall() {
			return store.getCurrentProjectConfig().useBeforeCallTime;
		},
		async manageTimesheetReport(subjectId, script = { id: 0, fileName: null, ext: 'xlsx' }, sendEmail = false) {
			let fileName = this.getFileNameScript(FILENAME_REPORT.REPORT_TS, script.id, script.fileName);

			await timeSheetReport(
				{
					subjectId,
					sendEmail
				},
				script.id
			)
				.then((result) => {
					if (!sendEmail) {
						this.forceDownload(result, fileName + '.' + script.ext);
					}
				})
				.catch((e) => {
					console.error(e);
				})
				.finally(() => (this.isLoadingSendMail = false));
		},
		timeSheetRerpotOnClosed() {
			this.isScriptTimeSheetReportOpen = false;
			this.isLoadingSendMail = false;
			this.isLoadingDownload = false;
		},
		handleLoading(payload) {
			if (payload) {
				this.isLoadingSendMail = false;
				this.isLoadingDownload = false;
			}
		}
	},
	async created() {
		this.$nextTick(async () => {
			this.myContracts = store.state.myContracts;
			if (this.curDay.date.length === 0) {
				this.firstTimeInitialisation();
			}
			await this.reloadData();
		});
	}
};
</script>
<style lang="scss" scoped>
.date-title {
	font-weight: 500;
	font-size: 1.75rem;
	color: rgba(6, 38, 62, 0.74);
}
.title-ts-detail {
	margin-left: -12px;
	margin-right: -12px;
}
.border-encoded {
	border: 2px solid #cad0d7;
	border-radius: 5px;
}
.date-title {
	display: inline-flex;
	justify-content: center !important;
	font-weight: 500;
	padding: 4px 0 4px 0;
	// font-size: 1.75rem;
	color: rgba(6, 38, 62, 0.74);
}
.name-title {
	// color: #3c7442;
	font-size: 14px;
	font-weight: 500;
	color: #06263e;
}
</style>
