<template>
	<div
		v-if="data && (data.jsError === true || (data.jsError === false && data.jsLog && data.jsLog.length > 0)) && lucideIcon"
		class="mr-1 cursor-pointer"
		:id="uniqueId"
	>
		<component :is="getLucideIcon(lucideIcon.name)" :color="lucideIcon.color" :size="sizeIcon" :stroke-width="strokeWidth" />
		<b-tooltip :target="uniqueId" placement="left" triggers="hover">
			<div class="text-left p-1 v-bootstrap-tooltip-custom" v-html="data.jsLog" style="font-size: 1rem"></div>
		</b-tooltip>
	</div>
</template>
<script>
import LanguageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import { generateId } from '~helpers';
import _ from 'lodash';
import { COLOR_ACTION } from '@/shared/constants';

export default {
	name: 'SalaryLog',
	mixins: [LanguageMessages, GlobalMixin],
	props: {
		item: {
			type: Object,
			required: true,
			default: null
		},
		sizeIcon: {
			type: [String, Number],
			required: false,
			default: 18
		},
		strokeWidth: {
			type: [String, Number],
			required: false,
			default: 2.25
		}
	},
	data() {
		return { data: null, uniqueId: null, lucideIcon: null };
	},
	watch: {
		item: {
			handler(value) {
				if (!_.isNil(value)) {
					this.data = value;
					this.uniqueId = generateId(this.data.id, 'salary-id');

					if (this.data.jsError === true) {
						this.lucideIcon = {
							name: 'AlertCircle',
							color: COLOR_ACTION.WARNING.dark
						};
					} else {
						if (this.data.jsLog && this.data.jsLog.length > 0) {
							this.lucideIcon = {
								name: 'Info',
								color: COLOR_ACTION.INFO.dark
							};
						}
					}
				}
			},
			deep: true,
			immediate: true
		}
	}
};
</script>
<style lang="scss">
.tooltip-inner:has(.v-bootstrap-tooltip-custom) {
	max-width: 450px !important;
	padding: 0.25rem 0.5rem !important;
	color: #fff !important;
	text-align: center !important;
	background-color: #000 !important;
	border-radius: 0.25rem !important;
	margin-left: -200px !important;
}
</style>
