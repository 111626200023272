var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "container-mobile animated fadeIn" }, [
      _c(
        "div",
        { staticClass: "content-detailsMyTimesheet-mobile" },
        [
          _c(
            "b-row",
            {
              staticStyle: { cursor: "pointer" },
              on: { click: _vm.handleClickBack },
            },
            [
              _c(
                "b-col",
                { staticStyle: { "padding-top": "2px" }, attrs: { cols: "1" } },
                [
                  _c("ArrowLeft", {
                    attrs: {
                      color: "#06263E",
                      size: _vm.$screen.width < 411 ? 18 : 25,
                    },
                  }),
                ],
                1
              ),
              _c("b-col", { attrs: { cols: "10" } }, [
                _c("h1", [
                  _vm._v(
                    _vm._s(_vm.currentTimesheet.strDateString) +
                      " - " +
                      _vm._s(_vm.currentTimesheet.endDateString)
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "b-row",
            { staticStyle: { "margin-top": "20px" } },
            [
              _c("b-col", [
                _c("div", { staticClass: "details-timesheet" }, [
                  _c("ul", [
                    !_vm.isFilmSingle
                      ? _c("li", [
                          _c("div", { staticClass: "column-1" }, [
                            _vm._v(_vm._s(_vm.FormMSG(15, "Status"))),
                          ]),
                          _c("div", { staticClass: "column-2" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "wrap-status d-flex justify-content-end",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    class: `status ${_vm.currentTimesheet.statusClass}`,
                                    staticStyle: {
                                      "font-size": "0.825rem",
                                      border:
                                        "0.005rem solid rgba(226, 230, 235, 0.65)",
                                      "font-weight": "500",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.currentTimesheet.validatedStatus
                                        ) +
                                        "\n\t\t\t\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    !_vm.isFilmSingle &&
                    (_vm.currentTimesheet.validated === 4 ||
                      _vm.currentTimesheet.validated === 16) &&
                    _vm.currentTimesheet.comment &&
                    _vm.currentTimesheet.comment.length > 0
                      ? _c(
                          "li",
                          {
                            staticClass: "timesheet-comment border-0 rounded-0",
                          },
                          [
                            _c("p", [
                              _vm._v(_vm._s(_vm.currentTimesheet.comment)),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _c("li", [
                      _c("div", { staticClass: "column-1" }, [
                        _vm._v(_vm._s(_vm.FormMSG(16, "Worked"))),
                      ]),
                      _c("div", { staticClass: "column-2" }, [
                        _vm._v(
                          _vm._s(_vm.getHhMmString(_vm.currentTimesheet.hhmm))
                        ),
                      ]),
                    ]),
                    _vm.showWeeklyOvt() ||
                    _vm.showWeeklyOvt1() ||
                    _vm.showWeeklyOvt2()
                      ? _c("li", [
                          _c("div", { staticClass: "column-1" }, [
                            _vm._v(_vm._s(_vm.FormMSG(175, "Weekly Overtime"))),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-end align-items-center w-100",
                            },
                            [
                              _vm.currentTimesheet.weeklyTotalOvertime != 0
                                ? _c(
                                    "button",
                                    {
                                      directives: [
                                        {
                                          name: "b-tooltip",
                                          rawName:
                                            "v-b-tooltip.hover.left.html",
                                          value:
                                            _vm.tooltipTsContentWeeklyTotalOvertime(
                                              _vm.currentTimesheet
                                            ),
                                          expression:
                                            "tooltipTsContentWeeklyTotalOvertime(currentTimesheet)",
                                          modifiers: {
                                            hover: true,
                                            left: true,
                                            html: true,
                                          },
                                        },
                                      ],
                                      staticClass:
                                        "btn-transparent text-color-rhapsody-in-blue",
                                    },
                                    [
                                      _c(_vm.getLucideIcon("Info"), {
                                        tag: "component",
                                        attrs: { color: "#225CBD", size: 16 },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                { staticStyle: { "margin-top": "4px" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getHhMmString(
                                        _vm.currentTimesheet
                                          .weeklyTotalOvertimeStr
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm.showOvt() || _vm.showOvt1() || _vm.showOvt2()
                      ? _c("li", [
                          _c("div", { staticClass: "column-1" }, [
                            _vm._v(_vm._s(_vm.FormMSG(180, "Daily Overtime"))),
                          ]),
                          _c("div", { staticClass: "column-2" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-content-end align-items-center w-100",
                              },
                              [
                                _vm.currentTimesheet.totMinOvertime != 0
                                  ? _c(
                                      "button",
                                      {
                                        directives: [
                                          {
                                            name: "b-tooltip",
                                            rawName:
                                              "v-b-tooltip.hover.left.html",
                                            value:
                                              _vm.tooltipTsContentDailyOvertime(
                                                _vm.currentTimesheet
                                              ),
                                            expression:
                                              "tooltipTsContentDailyOvertime(currentTimesheet)",
                                            modifiers: {
                                              hover: true,
                                              left: true,
                                              html: true,
                                            },
                                          },
                                        ],
                                        staticClass:
                                          "btn-transparent text-color-rhapsody-in-blue",
                                      },
                                      [
                                        _c(_vm.getLucideIcon("Info"), {
                                          tag: "component",
                                          attrs: { color: "#225CBD", size: 16 },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  { staticStyle: { "margin-top": "4px" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getHhMmString(
                                          _vm.currentTimesheet.tothhmmOvt
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.showTransport() ||
                    _vm.showNightHours() ||
                    _vm.showTooEarlyHours()
                      ? _c("li", [
                          _c("div", { staticClass: "column-1" }, [
                            _vm._v(_vm._s(_vm.FormMSG(20, "Transport"))),
                          ]),
                          _c("div", { staticClass: "column-2" }, [
                            _vm._v(
                              _vm._s(
                                _vm.getHhMmString(
                                  _vm.currentTimesheet.hhmmTransport
                                )
                              )
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _c("li", [
                      _c("div", { staticClass: "column-1" }, [
                        _vm._v(_vm._s(_vm.FormMSG(21, "Night"))),
                      ]),
                      _c("div", { staticClass: "column-2" }, [
                        _vm._v(
                          _vm._s(
                            _vm.getHhMmString(_vm.currentTimesheet.hhmmNight)
                          )
                        ),
                      ]),
                    ]),
                    _c("li", [
                      _c("div", { staticClass: "column-1" }, [
                        _vm._v(_vm._s(_vm.FormMSG(22, "Anticipated"))),
                      ]),
                      _c("div", { staticClass: "column-2" }, [
                        _vm._v(
                          _vm._s(
                            _vm.getHhMmString(_vm.currentTimesheet.hhmmTooEarly)
                          )
                        ),
                      ]),
                    ]),
                    _vm.showPreCall()
                      ? _c("li", [
                          _c("div", { staticClass: "column-1" }, [
                            _vm._v(_vm._s(_vm.FormMSG(222, "Precall"))),
                          ]),
                          _c("div", { staticClass: "column-2" }, [
                            _vm._v(_vm._s(_vm.currentTimesheet.beforeCallTime)),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.showLunchPerDiem() ||
                    _vm.showHotelPerDiem() ||
                    _vm.showDinnerPerDiem() ||
                    _vm.showAbroadPerDiem()
                      ? _c("li", [
                          _c("div", { staticClass: "column-1" }, [
                            _vm._v(_vm._s(_vm.FormMSG(220, "Per Diem"))),
                          ]),
                          _c("div", { staticClass: "column-2" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-content-end align-items-center w-100",
                              },
                              [
                                _vm.currentTimesheet.weeklyTotPerDiem > 0
                                  ? _c(
                                      "button",
                                      {
                                        directives: [
                                          {
                                            name: "b-tooltip",
                                            rawName:
                                              "v-b-tooltip.hover.left.html",
                                            value: _vm.tooltipTsContentPerIdem(
                                              _vm.currentTimesheet
                                            ),
                                            expression:
                                              "tooltipTsContentPerIdem(currentTimesheet)",
                                            modifiers: {
                                              hover: true,
                                              left: true,
                                              html: true,
                                            },
                                          },
                                        ],
                                        staticClass:
                                          "btn-transparent text-color-rhapsody-in-blue",
                                      },
                                      [
                                        _c(_vm.getLucideIcon("Info"), {
                                          tag: "component",
                                          attrs: { color: "#225CBD", size: 16 },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  { staticStyle: { "margin-top": "4px" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getCurrency(
                                          _vm.currentTimesheet.weeklyTotPerDiem
                                        )
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.currentTimesheet.dailyTotAllowances > 0 ||
                    _vm.currentTimesheet.weeklyTotAllowances > 0
                      ? _c("li", [
                          _c("div", { staticClass: "column-1" }, [
                            _vm._v(_vm._s(_vm.FormMSG(221, "Allowances"))),
                          ]),
                          _c("div", { staticClass: "column-2" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-content-end align-items-center w-100",
                              },
                              [
                                _vm.currentTimesheet.dailyTotAllowances > 0 ||
                                _vm.currentTimesheet.weeklyTotAllowances > 0
                                  ? _c(
                                      "button",
                                      {
                                        directives: [
                                          {
                                            name: "b-tooltip",
                                            rawName:
                                              "v-b-tooltip.hover.left.html",
                                            value:
                                              _vm.tooltipTsContentAllowance(
                                                _vm.currentTimesheet
                                              ),
                                            expression:
                                              "tooltipTsContentAllowance(currentTimesheet)",
                                            modifiers: {
                                              hover: true,
                                              left: true,
                                              html: true,
                                            },
                                          },
                                        ],
                                        staticClass:
                                          "btn-transparent text-color-rhapsody-in-blue",
                                      },
                                      [
                                        _c(_vm.getLucideIcon("Info"), {
                                          tag: "component",
                                          attrs: { color: "#225CBD", size: 16 },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  { staticStyle: { "margin-top": "4px" } },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.getCurrency(
                                            _vm.currentTimesheet
                                              .dailyTotAllowances +
                                              _vm.currentTimesheet
                                                .weeklyTotAllowances
                                          )
                                        ) +
                                        "\n\t\t\t\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.canSeeSalary
                      ? _c("li", [
                          _c("div", { staticClass: "column-1" }, [
                            _vm._v(_vm._s(_vm.FormMSG(23, "Salary"))),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-row align-items-center justify-content-end w-100",
                            },
                            [
                              _c("salary-log", {
                                attrs: { item: _vm.currentTimesheet },
                              }),
                              _c("div", { staticClass: "column-2" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.getCurrency(_vm.currentTimesheet.salary)
                                  )
                                ),
                              ]),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _c("li", [
                      _c("div", { staticClass: "column-1" }, [
                        _vm._v(_vm._s(_vm.FormMSG(102, "CO2"))),
                      ]),
                      _c("div", { staticClass: "column-2" }, [
                        _vm._v(
                          _vm._s(_vm.getCO2(_vm.currentTimesheet.kgCoTwo))
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          !_vm.isFilmSingle
            ? _c(
                "b-row",
                [
                  _c("b-col", [
                    _c(
                      "div",
                      { staticClass: "float-right" },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "mr-2",
                            attrs: { variant: "outline-primary" },
                            on: { click: _vm.handleClickDownload },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(1, "Download")) +
                                "\n\t\t\t\t\t\t\t"
                            ),
                            _c("Download", {
                              attrs: { color: "#00A09C", size: 16 },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "outline-primary" },
                            on: { click: _vm.handleClickShare },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(2, "Share invoice")) +
                                "\n\t\t\t\t\t\t\t"
                            ),
                            _c("Share", {
                              attrs: { color: "#00A09C", size: 16 },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            : _c(
                "b-row",
                [
                  _c("b-col", [
                    _c(
                      "div",
                      { staticClass: "float-right" },
                      [
                        _vm.isFilmSingle
                          ? _c("share-information", {
                              staticStyle: { "margin-right": "5px" },
                              attrs: {
                                "btn-label": _vm.FormMSG(45, "Share invoice"),
                                "title-modal": _vm.FormMSG(
                                  46,
                                  "Share the invoice expense to :"
                                ),
                                "current-timesheet": _vm.curTs,
                                "is-send-invoice": true,
                                isDisabled: !_vm.isFilmSinglePaying,
                                "send-a-copy": "",
                                "name-modal": "modal-share-invoice-timesheet",
                                icon: "fa fa-file-text",
                                "variant-btn": "outline-primary",
                              },
                            })
                          : _vm._e(),
                        _vm.isFilmSingle
                          ? _c("share-information", {
                              attrs: {
                                "btn-label": _vm.FormMSG(296, "Share"),
                                "title-modal": _vm.FormMSG(
                                  297,
                                  "Share timesheet"
                                ),
                                "current-timesheet": _vm.curTs,
                                "send-a-copy": "",
                                "variant-btn": "outline-primary",
                                "name-modal": "modal-share-timesheet",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
          _c("h2", { staticStyle: { "margin-top": "20px" } }, [
            _vm._v(_vm._s(_vm.FormMSG(3, "Workdays"))),
          ]),
          _vm._l(_vm.listTsDays, function (tsDay, index) {
            return _c(
              "div",
              { key: index, staticStyle: { padding: "16px" } },
              [
                _c(
                  "b-row",
                  {
                    staticStyle: {
                      "background-color": "#f5f7f9",
                      padding: "16px 8px 16px 8px",
                      "border-radius": "8px 8px 0 0",
                    },
                  },
                  [
                    _c(
                      "b-col",
                      { staticClass: "p-0" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-row justify-content-start align-items-center",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "col-sm-6 text-left",
                                staticStyle: {
                                  color: "#06263e",
                                  "font-size": "0.925rem",
                                },
                              },
                              [_vm._v(_vm._s(tsDay.dateString))]
                            ),
                            !_vm.isFilmSingle
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-sm-6 wrap-status d-flex justify-content-end",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        class: `status ${tsDay.statusClass}`,
                                        staticStyle: {
                                          "font-size": "0.7rem",
                                          "font-weight": "500",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(tsDay.validatedStatus) +
                                            "\n\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                        !_vm.isFilmSingle &&
                        (tsDay.validated === 4 || tsDay.validated === 16) &&
                        tsDay.comment &&
                        tsDay.comment.length > 0
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex flex-row timesheet-comment mt-2",
                              },
                              [
                                _c("p", { staticClass: "px-2" }, [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t" +
                                      _vm._s(tsDay.comment) +
                                      "\n\t\t\t\t\t\t\t"
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "b-row",
                          { staticStyle: { "margin-top": "16px" } },
                          [
                            _vm.canSeeSalary
                              ? _c(
                                  "b-col",
                                  {
                                    staticClass: "text-center",
                                    attrs: { cols: "4" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex flex-row align-items-center justify-content-center w-100",
                                      },
                                      [
                                        _c("salary-log", {
                                          attrs: { item: _vm.currentTimesheet },
                                        }),
                                        _c("h2", [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.getCurrency(tsDay.salary)
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c("p", [
                                      _vm._v(_vm._s(_vm.FormMSG(75, "Salary"))),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "b-col",
                              {
                                staticClass: "text-center",
                                attrs: { cols: "4" },
                              },
                              [
                                _c("h2", [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t\t" +
                                      _vm._s(_vm.getHhMmString(tsDay.hhmm)) +
                                      "\n\t\t\t\t\t\t\t\t"
                                  ),
                                ]),
                                _c(
                                  "p",
                                  [
                                    _c("Clock10", {
                                      attrs: { color: "#06263ED6", size: 16 },
                                    }),
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t\t" +
                                        _vm._s(_vm.FormMSG(76, "Worked")) +
                                        "\n\t\t\t\t\t\t\t\t"
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "b-col",
                              {
                                staticClass: "text-center pr-0 pl-0",
                                attrs: { cols: "4" },
                              },
                              [
                                _c("h2", [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t\t" +
                                      _vm._s(_vm.getCO2(tsDay.kgCoTwo)) +
                                      "\n\t\t\t\t\t\t\t\t"
                                  ),
                                ]),
                                _c(
                                  "p",
                                  [
                                    _c("Sprout", {
                                      attrs: { color: "#00A09C", size: 16 },
                                    }),
                                    _vm._v("\n\t\t\t\t\t\t\t\t\tCO"),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          display: "inline-block",
                                          "font-size": "8px",
                                        },
                                      },
                                      [_vm._v("2")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("b-collapse", { attrs: { id: tsDay.id } }, [
                  _c(
                    "div",
                    { staticStyle: { padding: "8px" } },
                    [
                      _c(
                        "b-row",
                        { staticClass: "pt-2 pb-3" },
                        [
                          _c("b-col", [
                            _c("div", { staticClass: "details-timesheet" }, [
                              _c("ul", [
                                _c("li", [
                                  _c("div", { staticClass: "column-1" }, [
                                    _vm._v(_vm._s(_vm.FormMSG(51, "Date"))),
                                  ]),
                                  _c("div", { staticClass: "column-2" }, [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                        _vm._s(_vm.rendDate(tsDay.date)) +
                                        "\n\t\t\t\t\t\t\t\t\t\t\t"
                                    ),
                                  ]),
                                ]),
                                _c("li", [
                                  _c("div", { staticClass: "column-1" }, [
                                    _vm._v(_vm._s(_vm.FormMSG(52, "Start"))),
                                  ]),
                                  _c("div", { staticClass: "column-2" }, [
                                    _vm._v(_vm._s(_vm.getHours(tsDay.strTime))),
                                  ]),
                                ]),
                                _c("li", [
                                  _c("div", { staticClass: "column-1" }, [
                                    _vm._v(_vm._s(_vm.FormMSG(53, "End"))),
                                  ]),
                                  _c("div", { staticClass: "column-2" }, [
                                    _vm._v(_vm._s(_vm.getHours(tsDay.endTime))),
                                  ]),
                                ]),
                                _c("li", [
                                  _c("div", { staticClass: "column-1" }, [
                                    _vm._v(_vm._s(_vm.FormMSG(54, "Type"))),
                                  ]),
                                  _vm.canSeeSalary
                                    ? _c("div", { staticClass: "column-2" }, [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.getFieldValueByShowGridDetails(
                                                true,
                                                tsDay.dayType,
                                                tsDay.salaryBase
                                              )
                                            ) +
                                            "\n\t\t\t\t\t\t\t\t\t\t\t"
                                        ),
                                      ])
                                    : _c("div", { staticClass: "column-2" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getFieldValueByShowGridDetails(
                                              false,
                                              tsDay.dayType
                                            )
                                          )
                                        ),
                                      ]),
                                ]),
                                _vm.showOvt()
                                  ? _c("li", [
                                      _c("div", { staticClass: "column-1" }, [
                                        _vm._v(
                                          _vm._s(_vm.FormMSG(169, "Overtime"))
                                        ),
                                      ]),
                                      _vm.canSeeSalary
                                        ? _c(
                                            "div",
                                            { staticClass: "column-2" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  tsDay.hhmmOvt +
                                                    " | " +
                                                    _vm.getCurrency(
                                                      tsDay.salaryOvertime
                                                    )
                                                )
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "div",
                                            { staticClass: "column-2" },
                                            [_vm._v(_vm._s(tsDay.hhmmOvt))]
                                          ),
                                    ])
                                  : _vm._e(),
                                _vm.showOvt1()
                                  ? _c("li", [
                                      _c("div", { staticClass: "column-1" }, [
                                        _vm._v(
                                          _vm._s(_vm.FormMSG(170, "Overtime 1"))
                                        ),
                                      ]),
                                      _vm.canSeeSalary
                                        ? _c(
                                            "div",
                                            { staticClass: "column-2" },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    tsDay.hhmmOvt1 +
                                                      " | " +
                                                      _vm.getCurrency(
                                                        tsDay.salaryOvertime1
                                                      )
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "div",
                                            { staticClass: "column-2" },
                                            [_vm._v(_vm._s(tsDay.hhmmOvt1))]
                                          ),
                                    ])
                                  : _vm._e(),
                                _vm.showOvt2()
                                  ? _c("li", [
                                      _c("div", { staticClass: "column-1" }, [
                                        _vm._v(
                                          _vm._s(_vm.FormMSG(171, "Overtime 2"))
                                        ),
                                      ]),
                                      _vm.canSeeSalary
                                        ? _c(
                                            "div",
                                            { staticClass: "column-2" },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    tsDay.hhmmOvt2 +
                                                      " | " +
                                                      _vm.getCurrency(
                                                        tsDay.salaryOvertime2
                                                      )
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "div",
                                            { staticClass: "column-2" },
                                            [_vm._v(_vm._s(tsDay.hhmmOvt2))]
                                          ),
                                    ])
                                  : _vm._e(),
                                _vm.showTransport()
                                  ? _c("li", [
                                      _c("div", { staticClass: "column-1" }, [
                                        _vm._v(
                                          _vm._s(_vm.FormMSG(158, "Transport"))
                                        ),
                                      ]),
                                      _vm.canSeeSalary
                                        ? _c(
                                            "div",
                                            { staticClass: "column-2" },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    tsDay.hhmmTransport +
                                                      " | " +
                                                      _vm.getCurrency(
                                                        tsDay.salaryTransport
                                                      )
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "div",
                                            { staticClass: "column-2" },
                                            [
                                              _vm._v(
                                                _vm._s(tsDay.hhmmTransport)
                                              ),
                                            ]
                                          ),
                                    ])
                                  : _vm._e(),
                                _vm.showNightHours()
                                  ? _c("li", [
                                      _c("div", { staticClass: "column-1" }, [
                                        _vm._v(
                                          _vm._s(_vm.FormMSG(57, "Night"))
                                        ),
                                      ]),
                                      _vm.canSeeSalary
                                        ? _c(
                                            "div",
                                            { staticClass: "column-2" },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    tsDay.hhmmNight +
                                                      " | " +
                                                      _vm.getCurrency(
                                                        tsDay.salaryHourNight
                                                      )
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "div",
                                            { staticClass: "column-2" },
                                            [_vm._v(_vm._s(tsDay.hhmmNight))]
                                          ),
                                    ])
                                  : _vm._e(),
                                _vm.showTooEarlyHours()
                                  ? _c("li", [
                                      _c("div", { staticClass: "column-1" }, [
                                        _vm._v(
                                          _vm._s(_vm.FormMSG(58, "Anticipated"))
                                        ),
                                      ]),
                                      _vm.canSeeSalary
                                        ? _c(
                                            "div",
                                            { staticClass: "column-2" },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    tsDay.hhmmTooEarly +
                                                      " | " +
                                                      _vm.getCurrency(
                                                        tsDay.salaryHourTooEarly
                                                      )
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "div",
                                            { staticClass: "column-2" },
                                            [_vm._v(_vm._s(tsDay.hhmmTooEarly))]
                                          ),
                                    ])
                                  : _vm._e(),
                                _vm.showPreCall()
                                  ? _c("li", [
                                      _c("div", { staticClass: "column-1" }, [
                                        _vm._v(
                                          _vm._s(_vm.FormMSG(222, "Precall"))
                                        ),
                                      ]),
                                      _c("div", { staticClass: "column-2" }, [
                                        _vm._v(_vm._s(tsDay.beforeCallTime)),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm.showLunch()
                                  ? _c("li", [
                                      _c("div", { staticClass: "column-1" }, [
                                        _vm._v(
                                          _vm._s(_vm.FormMSG(60, "Lunch start"))
                                        ),
                                      ]),
                                      _c("div", { staticClass: "column-2" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getHours(tsDay.lunchStrTime)
                                          )
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm.showLunch()
                                  ? _c("li", [
                                      _c("div", { staticClass: "column-1" }, [
                                        _vm._v(
                                          _vm._s(_vm.FormMSG(65, "Lunch end"))
                                        ),
                                      ]),
                                      _c("div", { staticClass: "column-2" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getHours(tsDay.lunchEndTime)
                                          )
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm.showLunchPerDiem() ||
                                _vm.showHotelPerDiem() ||
                                _vm.showDinnerPerDiem() ||
                                _vm.showAbroadPerDiem()
                                  ? _c("li", [
                                      _c("div", { staticClass: "column-1" }, [
                                        _vm._v(
                                          _vm._s(_vm.FormMSG(220, "Per Diem"))
                                        ),
                                      ]),
                                      _c("div", { staticClass: "column-2" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex justify-content-end align-items-center w-100",
                                          },
                                          [
                                            tsDay.totPerDiem > 0
                                              ? _c(
                                                  "button",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "b-tooltip",
                                                        rawName:
                                                          "v-b-tooltip.hover.left.html",
                                                        value:
                                                          _vm.tooltipContentPerIdem(
                                                            tsDay
                                                          ),
                                                        expression:
                                                          "tooltipContentPerIdem(tsDay)",
                                                        modifiers: {
                                                          hover: true,
                                                          left: true,
                                                          html: true,
                                                        },
                                                      },
                                                    ],
                                                    staticClass:
                                                      "btn-transparent text-color-rhapsody-in-blue",
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon("Info"),
                                                      {
                                                        tag: "component",
                                                        attrs: {
                                                          color: "#225CBD",
                                                          size: 16,
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "margin-top": "4px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getCurrency(
                                                      tsDay.totPerDiem
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _c("li", [
                                  _c("div", { staticClass: "column-1" }, [
                                    _vm._v(
                                      _vm._s(_vm.FormMSG(221, "Allowances"))
                                    ),
                                  ]),
                                  _c("div", { staticClass: "column-2" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-end align-items-center w-100",
                                      },
                                      [
                                        tsDay.totAllowances > 0
                                          ? _c(
                                              "button",
                                              {
                                                directives: [
                                                  {
                                                    name: "b-tooltip",
                                                    rawName:
                                                      "v-b-tooltip.hover.left.html",
                                                    value:
                                                      _vm.tooltipContentAllowance(
                                                        tsDay
                                                      ),
                                                    expression:
                                                      "tooltipContentAllowance(tsDay)",
                                                    modifiers: {
                                                      hover: true,
                                                      left: true,
                                                      html: true,
                                                    },
                                                  },
                                                ],
                                                staticClass:
                                                  "btn-transparent text-color-rhapsody-in-blue",
                                              },
                                              [
                                                _c(_vm.getLucideIcon("Info"), {
                                                  tag: "component",
                                                  attrs: {
                                                    color: "#225CBD",
                                                    size: 16,
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "margin-top": "4px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getCurrency(
                                                  tsDay.totAllowances
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                                _c("li", [
                                  _c("div", { staticClass: "column-1" }, [
                                    _vm._v(_vm._s(_vm.FormMSG(59, "Rate"))),
                                  ]),
                                  _c("div", { staticClass: "column-2" }, [
                                    _vm._v(_vm._s(tsDay.dayRate + "%")),
                                  ]),
                                ]),
                                _c("li", [
                                  _c("div", { staticClass: "column-1" }, [
                                    _vm._v(
                                      _vm._s(_vm.FormMSG(5001, "Comment"))
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "column-2" },
                                    [
                                      tsDay.remark
                                        ? _c(
                                            "Button",
                                            {
                                              directives: [
                                                {
                                                  name: "tooltip",
                                                  rawName:
                                                    "v-tooltip.left.focus",
                                                  value: tsDay.remark,
                                                  expression: "tsDay.remark",
                                                  modifiers: {
                                                    left: true,
                                                    focus: true,
                                                  },
                                                },
                                              ],
                                              staticClass:
                                                "p-0 p-button-text p-button-info",
                                            },
                                            [_c("MessageCircle")],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                            ]),
                          ]),
                          _c(
                            "b-col",
                            {
                              staticClass: "text-right",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-end align-items-center w-100",
                                },
                                [
                                  tsDay.validated !== 0 &&
                                  tsDay.validated !== 16 &&
                                  tsDay.validated !== 4 &&
                                  !_vm.isFilmSingle
                                    ? _c("div", {}, [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn-transparent fsz-13 d-flex flex-row justify-content-end align-items-center",
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleEditTsDay(
                                                  tsDay
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "margin-bottom": "2px",
                                                  "margin-right": "4px",
                                                },
                                              },
                                              [
                                                _c("Eye", {
                                                  attrs: {
                                                    color: "#06263ED6",
                                                    size: 14,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  color: "#06263ed6",
                                                  "font-size": "14px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.FormMSG(85000, "View")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex justify-content-end align-items-center w-100",
                                        },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn-transparent fsz-13 mr-2 d-flex flex-row justify-content-end align-items-center",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleEditTsDay(
                                                    tsDay
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom": "2px",
                                                    "margin-right": "4px",
                                                  },
                                                },
                                                [
                                                  _c("Edit", {
                                                    attrs: {
                                                      color: "#06263ED6",
                                                      size: 14,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    color: "#06263ed6",
                                                    "font-size": "14px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(30122, "Edit")
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn-transparent fsz-13 d-flex flex-row justify-content-end align-items-center",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleClickDeleteTsDay(
                                                    tsDay
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom": "2px",
                                                    "margin-right": "4px",
                                                  },
                                                },
                                                [
                                                  _c("Trash2", {
                                                    attrs: {
                                                      color: "#06263ED6",
                                                      size: 14,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    color: "#06263ed6",
                                                    "font-size": "14px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.FormMSG(
                                                        11456,
                                                        "Delete"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "b-row",
                  {
                    directives: [
                      {
                        name: "b-toggle",
                        rawName: "v-b-toggle",
                        value: tsDay.id,
                        expression: "tsDay.id",
                      },
                    ],
                    style: `margin-top: ${tsDay.showDetails ? "-20px" : "4px"}`,
                  },
                  [
                    _c(
                      "b-col",
                      {
                        staticStyle: {
                          "background-color": "#f8f9fb",
                          padding: "8px",
                          "border-radius": "0 0 8px 8px",
                        },
                      },
                      [
                        _c("div", { staticClass: "float-right" }, [
                          _c(
                            "p",
                            {
                              staticStyle: {
                                color: "#225cbd",
                                cursor: "pointer",
                                "font-size": "13px !important",
                              },
                              on: {
                                click: function ($event) {
                                  tsDay.showDetails = !tsDay.showDetails
                                },
                              },
                            },
                            [
                              !tsDay.showDetails
                                ? _c(
                                    "span",
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.FormMSG(7, "Show Details")
                                          ) +
                                          " "
                                      ),
                                      _c("chevron-down", {
                                        attrs: { size: 16 },
                                      }),
                                    ],
                                    1
                                  )
                                : _c(
                                    "span",
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.FormMSG(8, "Hide Details")
                                          ) +
                                          " "
                                      ),
                                      _c("chevron-up", { attrs: { size: 16 } }),
                                    ],
                                    1
                                  ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          _vm.currentTimesheet.validated == 0
            ? _c(
                "div",
                [
                  _c(
                    "b-row",
                    { staticClass: "footer-fixed" },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticStyle: {
                                "font-size": "16px",
                                "font-weight": "700",
                              },
                              attrs: { variant: "primary", block: "" },
                              on: { click: _vm.sendForValidation },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.FormMSG(15210, "Send to validation"))
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }