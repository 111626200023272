<template>
	<div>
		<div class="container-mobile animated fadeIn">
			<div class="content-detailsMyTimesheet-mobile">
				<b-row style="cursor: pointer" @click="handleClickBack">
					<b-col cols="1" style="padding-top: 2px">
						<ArrowLeft color="#06263E" :size="$screen.width < 411 ? 18 : 25" />
					</b-col>
					<b-col cols="10">
						<h1>{{ currentTimesheet.strDateString }} - {{ currentTimesheet.endDateString }}</h1>
					</b-col>
				</b-row>
				<b-row style="margin-top: 20px">
					<b-col>
						<div class="details-timesheet">
							<ul>
								<li v-if="!isFilmSingle">
									<div class="column-1">{{ FormMSG(15, 'Status') }}</div>
									<div class="column-2">
										<div class="wrap-status d-flex justify-content-end">
											<div
												:class="`status ${currentTimesheet.statusClass}`"
												style="font-size: 0.825rem; border: 0.005rem solid rgba(226, 230, 235, 0.65); font-weight: 500"
											>
												{{ currentTimesheet.validatedStatus }}
											</div>
										</div>
									</div>
								</li>
								<li
									class="timesheet-comment border-0 rounded-0"
									v-if="
										!isFilmSingle &&
										(currentTimesheet.validated === 4 || currentTimesheet.validated === 16) &&
										currentTimesheet.comment &&
										currentTimesheet.comment.length > 0
									"
								>
									<p>{{ currentTimesheet.comment }}</p>
								</li>
								<li>
									<div class="column-1">{{ FormMSG(16, 'Worked') }}</div>
									<div class="column-2">{{ getHhMmString(currentTimesheet.hhmm) }}</div>
								</li>
								<li v-if="showWeeklyOvt() || showWeeklyOvt1() || showWeeklyOvt2()">
									<div class="column-1">{{ FormMSG(175, 'Weekly Overtime') }}</div>
									<div class="d-flex justify-content-end align-items-center w-100">
										<button
											class="btn-transparent text-color-rhapsody-in-blue"
											v-b-tooltip.hover.left.html="tooltipTsContentWeeklyTotalOvertime(currentTimesheet)"
											v-if="currentTimesheet.weeklyTotalOvertime != 0"
										>
											<component :is="getLucideIcon('Info')" color="#225CBD" :size="16" />
										</button>
										<div style="margin-top: 4px">{{ getHhMmString(currentTimesheet.weeklyTotalOvertimeStr) }}</div>
									</div>
								</li>
								<li v-if="showOvt() || showOvt1() || showOvt2()">
									<div class="column-1">{{ FormMSG(180, 'Daily Overtime') }}</div>
									<div class="column-2">
										<div class="d-flex justify-content-end align-items-center w-100">
											<button
												class="btn-transparent text-color-rhapsody-in-blue"
												v-b-tooltip.hover.left.html="tooltipTsContentDailyOvertime(currentTimesheet)"
												v-if="currentTimesheet.totMinOvertime != 0"
											>
												<component :is="getLucideIcon('Info')" color="#225CBD" :size="16" />
											</button>
											<div style="margin-top: 4px">{{ getHhMmString(currentTimesheet.tothhmmOvt) }}</div>
										</div>
									</div>
									<!-- <div class="column-2">{{ getHhMmString(currentTimesheet.tothhmmOvt) }}</div> -->
								</li>
								<li v-if="showTransport() || showNightHours() || showTooEarlyHours()">
									<div class="column-1">{{ FormMSG(20, 'Transport') }}</div>
									<div class="column-2">{{ getHhMmString(currentTimesheet.hhmmTransport) }}</div>
								</li>
								<li>
									<div class="column-1">{{ FormMSG(21, 'Night') }}</div>
									<div class="column-2">{{ getHhMmString(currentTimesheet.hhmmNight) }}</div>
								</li>
								<li>
									<div class="column-1">{{ FormMSG(22, 'Anticipated') }}</div>
									<div class="column-2">{{ getHhMmString(currentTimesheet.hhmmTooEarly) }}</div>
								</li>
								<!--
									@todo
									Julis
								-->
								<li v-if="showPreCall()">
									<div class="column-1">{{ FormMSG(222, 'Precall') }}</div>
									<div class="column-2">{{ currentTimesheet.beforeCallTime }}</div>
								</li>
								<li v-if="showLunchPerDiem() || showHotelPerDiem() || showDinnerPerDiem() || showAbroadPerDiem()">
									<div class="column-1">{{ FormMSG(220, 'Per Diem') }}</div>
									<div class="column-2">
										<div class="d-flex justify-content-end align-items-center w-100">
											<button
												class="btn-transparent text-color-rhapsody-in-blue"
												v-b-tooltip.hover.left.html="tooltipTsContentPerIdem(currentTimesheet)"
												v-if="currentTimesheet.weeklyTotPerDiem > 0"
											>
												<component :is="getLucideIcon('Info')" color="#225CBD" :size="16" />
											</button>
											<div style="margin-top: 4px">{{ getCurrency(currentTimesheet.weeklyTotPerDiem) }}</div>
										</div>
									</div>
								</li>
								<li v-if="currentTimesheet.dailyTotAllowances > 0 || currentTimesheet.weeklyTotAllowances > 0">
									<div class="column-1">{{ FormMSG(221, 'Allowances') }}</div>
									<div class="column-2">
										<div class="d-flex justify-content-end align-items-center w-100">
											<button
												class="btn-transparent text-color-rhapsody-in-blue"
												v-b-tooltip.hover.left.html="tooltipTsContentAllowance(currentTimesheet)"
												v-if="currentTimesheet.dailyTotAllowances > 0 || currentTimesheet.weeklyTotAllowances > 0"
											>
												<component :is="getLucideIcon('Info')" color="#225CBD" :size="16" />
											</button>
											<div style="margin-top: 4px">
												{{ getCurrency(currentTimesheet.dailyTotAllowances + currentTimesheet.weeklyTotAllowances) }}
											</div>
										</div>
									</div>
								</li>
								<li v-if="canSeeSalary">
									<div class="column-1">{{ FormMSG(23, 'Salary') }}</div>
									<div class="d-flex flex-row align-items-center justify-content-end w-100">
										<salary-log :item="currentTimesheet" />
										<div class="column-2">{{ getCurrency(currentTimesheet.salary) }}</div>
									</div>
								</li>
								<li>
									<div class="column-1">{{ FormMSG(102, 'CO2') }}</div>
									<div class="column-2">{{ getCO2(currentTimesheet.kgCoTwo) }}</div>
								</li>
							</ul>
						</div>
					</b-col>
				</b-row>
				<b-row v-if="!isFilmSingle">
					<b-col>
						<div class="float-right">
							<b-button variant="outline-primary" class="mr-2" @click="handleClickDownload">
								{{ FormMSG(1, 'Download') }}
								<Download color="#00A09C" :size="16" />
							</b-button>
							<b-button variant="outline-primary" @click="handleClickShare">
								{{ FormMSG(2, 'Share invoice') }}
								<Share color="#00A09C" :size="16" />
							</b-button>
						</div>
					</b-col>
				</b-row>
				<b-row v-else>
					<b-col>
						<div class="float-right">
							<share-information
								v-if="isFilmSingle"
								:btn-label="FormMSG(45, 'Share invoice')"
								:title-modal="FormMSG(46, 'Share the invoice expense to :')"
								:current-timesheet="curTs"
								:is-send-invoice="true"
								:isDisabled="!isFilmSinglePaying"
								send-a-copy
								name-modal="modal-share-invoice-timesheet"
								icon="fa fa-file-text"
								variant-btn="outline-primary"
								style="margin-right: 5px"
							/>
							<share-information
								v-if="isFilmSingle"
								:btn-label="FormMSG(296, 'Share')"
								:title-modal="FormMSG(297, 'Share timesheet')"
								:current-timesheet="curTs"
								send-a-copy
								variant-btn="outline-primary"
								name-modal="modal-share-timesheet"
							/>
						</div>
					</b-col>
				</b-row>
				<h2 style="margin-top: 20px">{{ FormMSG(3, 'Workdays') }}</h2>
				<div v-for="(tsDay, index) in listTsDays" :key="index" style="padding: 16px">
					<b-row style="background-color: #f5f7f9; padding: 16px 8px 16px 8px; border-radius: 8px 8px 0 0">
						<b-col class="p-0">
							<div class="d-flex flex-row justify-content-start align-items-center">
								<div class="col-sm-6 text-left" style="color: #06263e; font-size: 0.925rem">{{ tsDay.dateString }}</div>
								<div class="col-sm-6 wrap-status d-flex justify-content-end" v-if="!isFilmSingle">
									<div :class="`status ${tsDay.statusClass}`" style="font-size: 0.7rem; font-weight: 500">
										{{ tsDay.validatedStatus }}
									</div>
								</div>
							</div>
							<div
								class="d-flex flex-row timesheet-comment mt-2"
								v-if="!isFilmSingle && (tsDay.validated === 4 || tsDay.validated === 16) && tsDay.comment && tsDay.comment.length > 0"
							>
								<p class="px-2">
									{{ tsDay.comment }}
								</p>
							</div>
							<b-row style="margin-top: 16px">
								<b-col v-if="canSeeSalary" cols="4" class="text-center">
									<div class="d-flex flex-row align-items-center justify-content-center w-100">
										<salary-log :item="currentTimesheet" />
										<h2>
											{{ getCurrency(tsDay.salary) }}
										</h2>
									</div>
									<p>{{ FormMSG(75, 'Salary') }}</p>
								</b-col>
								<b-col cols="4" class="text-center">
									<h2>
										{{ getHhMmString(tsDay.hhmm) }}
									</h2>
									<p>
										<Clock10 color="#06263ED6" :size="16" />
										{{ FormMSG(76, 'Worked') }}
									</p>
								</b-col>
								<b-col cols="4" class="text-center pr-0 pl-0">
									<h2>
										{{ getCO2(tsDay.kgCoTwo) }}
									</h2>
									<p>
										<Sprout color="#00A09C" :size="16" />
										CO<span style="display: inline-block; font-size: 8px">2</span>
									</p>
								</b-col>
							</b-row>
						</b-col>
					</b-row>
					<b-collapse :id="tsDay.id">
						<div style="padding: 8px">
							<b-row class="pt-2 pb-3">
								<b-col>
									<div class="details-timesheet">
										<ul>
											<li>
												<div class="column-1">{{ FormMSG(51, 'Date') }}</div>
												<div class="column-2">
													{{ rendDate(tsDay.date) }}
												</div>
											</li>
											<li>
												<div class="column-1">{{ FormMSG(52, 'Start') }}</div>
												<div class="column-2">{{ getHours(tsDay.strTime) }}</div>
											</li>
											<li>
												<div class="column-1">{{ FormMSG(53, 'End') }}</div>
												<div class="column-2">{{ getHours(tsDay.endTime) }}</div>
											</li>
											<li>
												<div class="column-1">{{ FormMSG(54, 'Type') }}</div>
												<div class="column-2" v-if="canSeeSalary">
													{{ getFieldValueByShowGridDetails(true, tsDay.dayType, tsDay.salaryBase) }}
												</div>
												<div class="column-2" v-else>{{ getFieldValueByShowGridDetails(false, tsDay.dayType) }}</div>
											</li>
											<li v-if="showOvt()">
												<div class="column-1">{{ FormMSG(169, 'Overtime') }}</div>
												<div v-if="canSeeSalary" class="column-2">{{ tsDay.hhmmOvt + ' | ' + getCurrency(tsDay.salaryOvertime) }}</div>
												<div class="column-2" v-else>{{ tsDay.hhmmOvt }}</div>
											</li>
											<li v-if="showOvt1()">
												<div class="column-1">{{ FormMSG(170, 'Overtime 1') }}</div>
												<div v-if="canSeeSalary" class="column-2">
													{{ tsDay.hhmmOvt1 + ' | ' + getCurrency(tsDay.salaryOvertime1) }}
												</div>
												<div class="column-2" v-else>{{ tsDay.hhmmOvt1 }}</div>
											</li>
											<li v-if="showOvt2()">
												<div class="column-1">{{ FormMSG(171, 'Overtime 2') }}</div>
												<div v-if="canSeeSalary" class="column-2">
													{{ tsDay.hhmmOvt2 + ' | ' + getCurrency(tsDay.salaryOvertime2) }}
												</div>
												<div class="column-2" v-else>{{ tsDay.hhmmOvt2 }}</div>
											</li>
											<li v-if="showTransport()">
												<div class="column-1">{{ FormMSG(158, 'Transport') }}</div>
												<div v-if="canSeeSalary" class="column-2">
													{{ tsDay.hhmmTransport + ' | ' + getCurrency(tsDay.salaryTransport) }}
												</div>
												<div class="column-2" v-else>{{ tsDay.hhmmTransport }}</div>
											</li>
											<li v-if="showNightHours()">
												<div class="column-1">{{ FormMSG(57, 'Night') }}</div>
												<div v-if="canSeeSalary" class="column-2">
													{{ tsDay.hhmmNight + ' | ' + getCurrency(tsDay.salaryHourNight) }}
												</div>
												<div class="column-2" v-else>{{ tsDay.hhmmNight }}</div>
											</li>
											<li v-if="showTooEarlyHours()">
												<div class="column-1">{{ FormMSG(58, 'Anticipated') }}</div>
												<div v-if="canSeeSalary" class="column-2">
													{{ tsDay.hhmmTooEarly + ' | ' + getCurrency(tsDay.salaryHourTooEarly) }}
												</div>
												<div class="column-2" v-else>{{ tsDay.hhmmTooEarly }}</div>
											</li>
											<li v-if="showPreCall()">
												<div class="column-1">{{ FormMSG(222, 'Precall') }}</div>
												<div class="column-2">{{ tsDay.beforeCallTime }}</div>
											</li>
											<li v-if="showLunch()">
												<div class="column-1">{{ FormMSG(60, 'Lunch start') }}</div>
												<div class="column-2">{{ getHours(tsDay.lunchStrTime) }}</div>
											</li>
											<li v-if="showLunch()">
												<div class="column-1">{{ FormMSG(65, 'Lunch end') }}</div>
												<div class="column-2">{{ getHours(tsDay.lunchEndTime) }}</div>
											</li>
											<li v-if="showLunchPerDiem() || showHotelPerDiem() || showDinnerPerDiem() || showAbroadPerDiem()">
												<div class="column-1">{{ FormMSG(220, 'Per Diem') }}</div>
												<div class="column-2">
													<div class="d-flex justify-content-end align-items-center w-100">
														<button
															class="btn-transparent text-color-rhapsody-in-blue"
															v-b-tooltip.hover.left.html="tooltipContentPerIdem(tsDay)"
															v-if="tsDay.totPerDiem > 0"
														>
															<component :is="getLucideIcon('Info')" color="#225CBD" :size="16" />
														</button>
														<div style="margin-top: 4px">{{ getCurrency(tsDay.totPerDiem) }}</div>
													</div>
												</div>
											</li>
											<li>
												<div class="column-1">{{ FormMSG(221, 'Allowances') }}</div>
												<div class="column-2">
													<div class="d-flex justify-content-end align-items-center w-100">
														<button
															class="btn-transparent text-color-rhapsody-in-blue"
															v-b-tooltip.hover.left.html="tooltipContentAllowance(tsDay)"
															v-if="tsDay.totAllowances > 0"
														>
															<component :is="getLucideIcon('Info')" color="#225CBD" :size="16" />
														</button>
														<div style="margin-top: 4px">{{ getCurrency(tsDay.totAllowances) }}</div>
													</div>
												</div>
											</li>
											<li>
												<div class="column-1">{{ FormMSG(59, 'Rate') }}</div>
												<div class="column-2">{{ tsDay.dayRate + '%' }}</div>
											</li>
											<li>
												<div class="column-1">{{ FormMSG(5001, 'Comment') }}</div>
												<div class="column-2">
													<Button v-if="tsDay.remark" v-tooltip.left.focus="tsDay.remark" class="p-0 p-button-text p-button-info">
														<MessageCircle />
													</Button>
												</div>
											</li>
										</ul>
									</div>
								</b-col>
								<b-col cols="12" class="text-right">
									<div class="d-flex justify-content-end align-items-center w-100">
										<div v-if="tsDay.validated !== 0 && tsDay.validated !== 16 && tsDay.validated !== 4 && !isFilmSingle" class="">
											<button
												@click="handleEditTsDay(tsDay)"
												class="btn-transparent fsz-13 d-flex flex-row justify-content-end align-items-center"
											>
												<div style="margin-bottom: 2px; margin-right: 4px">
													<Eye color="#06263ED6" :size="14" />
												</div>
												<div style="color: #06263ed6; font-size: 14px">{{ FormMSG(85000, 'View') }}</div>
											</button>
										</div>
										<div class="d-flex justify-content-end align-items-center w-100" v-else>
											<button
												class="btn-transparent fsz-13 mr-2 d-flex flex-row justify-content-end align-items-center"
												@click="handleEditTsDay(tsDay)"
											>
												<div style="margin-bottom: 2px; margin-right: 4px">
													<Edit color="#06263ED6" :size="14" />
												</div>
												<div style="color: #06263ed6; font-size: 14px">{{ FormMSG(30122, 'Edit') }}</div>
											</button>
											<button
												@click="handleClickDeleteTsDay(tsDay)"
												class="btn-transparent fsz-13 d-flex flex-row justify-content-end align-items-center"
											>
												<div style="margin-bottom: 2px; margin-right: 4px">
													<Trash2 color="#06263ED6" :size="14" />
												</div>
												<div style="color: #06263ed6; font-size: 14px">{{ FormMSG(11456, 'Delete') }}</div>
											</button>
										</div>
									</div>
								</b-col>
							</b-row>
						</div>
					</b-collapse>
					<b-row v-b-toggle="tsDay.id" :style="`margin-top: ${tsDay.showDetails ? '-20px' : '4px'}`">
						<b-col style="background-color: #f8f9fb; padding: 8px; border-radius: 0 0 8px 8px">
							<div class="float-right">
								<p style="color: #225cbd; cursor: pointer; font-size: 13px !important" @click="tsDay.showDetails = !tsDay.showDetails">
									<span v-if="!tsDay.showDetails"> {{ FormMSG(7, 'Show Details') }}&nbsp;<chevron-down :size="16" /> </span>
									<span v-else> {{ FormMSG(8, 'Hide Details') }}&nbsp;<chevron-up :size="16" /> </span>
								</p>
							</div>
						</b-col>
					</b-row>
				</div>
				<div v-if="currentTimesheet.validated == 0">
					<b-row class="footer-fixed">
						<b-col cols="12">
							<b-button variant="primary" @click="sendForValidation" block style="font-size: 16px; font-weight: 700">{{
								FormMSG(15210, 'Send to validation')
							}}</b-button>
						</b-col>
					</b-row>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ArrowLeft, Download, Share, Clock10, Sprout, ChevronDown, ChevronUp } from 'lucide-vue';
import { rendCurrency, rendKgCo2 } from '~helpers';
import GlobalMixin from '@/mixins/global.mixin';
import moment from 'moment';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import { store } from '@/store';
import languageMessages from '@/mixins/languageMessages';
import ShareInformation from '../InfoSheet/ShareInformation';
import { isNil } from '@/shared/utils';
import { Info, Edit, Trash2, Eye } from 'lucide-vue';
import Button from 'primevue/button';
import { MessageCircle } from 'lucide-vue';
import * as icons from 'lucide-vue';
import { delTimesheet } from '@/cruds/timesheet.crud';
import { submitTimeSheetsSlice } from '@/cruds/timesheets-efc.crud';
import SalaryLog from '@/components/Timesheet/SalaryLog';

export default {
	name: 'DetailsTimesheetMobile',
	components: {
		Button,
		MessageCircle,
		ArrowLeft,
		Download,
		Share,
		Clock10,
		Sprout,
		ShareInformation,
		ChevronDown,
		ChevronUp,
		Info,
		Eye,
		Edit,
		Trash2,
		SalaryLog
	},
	mixins: [GlobalMixin, isSingleProjectMixin, languageMessages],
	props: {
		curTs: { type: Object, default: () => ({}) },
		tsDays: { type: Array, default: () => [] },
		curDay: { type: Object, default: () => ({}) }
	},
	data() {
		return {
			currentTimesheet: {},
			listTsDays: [],
			showGridDetails: true
		};
	},
	watch: {
		curTs: {
			handler(val) {
				if (val) {
					this.currentTimesheet = {
						...val,
						strDateString: moment(val.strDate).format('dddd, DD MMMM'),
						endDateString: moment(val.endDate).format('dddd, DD MMMM')
					};
				}
			},
			immediate: true
		},
		tsDays: {
			handler(val) {
				let result = [];
				if (val.length > 0) {
					for (let i = val.length - 1; i >= 0; i--) {
						result.push({
							...val[i],
							dateString: moment(val[i].date).format('dddd, DD MMMM'),
							showDetails: false
							// endTimeString: moment(val[i].endTime).format('dddd, DD MMMM')
						});
					}
				}
				this.listTsDays = result;
			},
			immediate: true
		}
	},
	computed: {
		canSeeSalary() {
			return !store.getCurrentProjectConfig().hideSalaryInfo || store.canViewGlobalBudget();
		},
		statuses() {
			return this.FormMenu(1008);
		},
		tsfieldsCollapseMobile() {
			const keys = ['salary', 'hhmm', 'kgCoTwo', 'rem', 'validatedStatus'];
			return this.tsfields.filter((t) => !keys.includes(t.key));
		},
		tsfields() {
			var flds = [];
			flds.push({
				key: 'isSelected',
				label: this.FormMSG(351, 'Selected'),
				formatter: (v) => v
			});
			var statusFld = {
				key: 'validatedStatus',
				label: this.FormMSG(50, 'Status'),
				sortable: true
			};
			// console.log("isfilmsingle:",this.isFilmSingle)
			if (!this.isFilmSingle) {
				flds.push(statusFld);
			}
			var fldsBase = [
				{
					key: 'date',
					label: this.FormMSG(51, 'Date'),
					formatter: (v) => v.substring(8, 10),
					sortable: true
				},
				{
					key: 'strTime',
					label: this.FormMSG(52, 'Start'),
					// formatter: v => v.split('T')[1].substring(0, 5),
					formatter: (v, z, root) => {
						if (isNil(root)) return '';
						const time = root.strTime.split('T')[1].substring(0, 5);
						return root.strTime.substring(8, 10) == root.date.substring(8, 10) ? time : time + ' (' + root.strTime.substring(8, 10) + ')';
					},
					sortable: false
				},
				{
					key: 'endTime',
					label: this.FormMSG(53, 'End'),
					formatter: (v, z, root) => {
						if (isNil(root)) return '';
						const strDay = new Date(root.strTime).getDay();
						const endDay = new Date(root.endTime).getDay();
						const time = root.endTime.split('T')[1].substring(0, 5);
						return root.endTime.substring(8, 10) == root.date.substring(8, 10) ? time : time + ' (' + root.endTime.substring(8, 10) + ')';
					},
					sortable: false
				},
				{
					key: 'dayType',
					label: this.FormMSG(54, 'Type'),
					formatter: (value, z, root) => {
						let separator = this.$screen.width < 992 ? ' | ' : '<br>';
						return this.getFieldValueByShowGridDetails(this.showGridDetails, value, root.salaryBase);
					},
					sortable: false
				},
				{
					key: 'hhmm',
					label: this.FormMSG(55, 'Hours'),
					formatter: (v, z, root) => {
						if (isNil(root)) return '';
						return !this.showGridDetails ? root.hhmm : root.hhmm;
					},
					sortable: false
				}
			];

			flds = flds.concat(fldsBase);
			//console.log("flds",flds)
			var ovtFld = {
				key: 'hhmmOvt',
				label: this.FormMSG(56, 'Ovt'),
				formatter: (v, z, root) => {
					let separator = this.$screen.width < 992 ? ' | ' : '<br>';
					return !this.showGridDetails ? root.hhmmOvt : root.hhmmOvt + separator + rendCurrency(root.salaryOvertime);
				}
			};
			if (this.showOvt()) {
				flds.push(ovtFld);
			}

			var ovt1Fld = {
				key: 'hhmmOvt1',
				label: this.FormMSG(156, 'Ovt1'),
				formatter: (v, z, root) => {
					let separator = this.$screen.width < 992 ? ' | ' : '<br>';
					return !this.showGridDetails ? root.hhmmOvt1 : root.hhmmOvt1 + separator + rendCurrency(root.salaryOvertime1);
				}
			};
			if (this.showOvt1()) {
				flds.push(ovt1Fld);
			}
			var ovt2Fld = {
				key: 'hhmmOvt2',
				label: this.FormMSG(157, 'Ovt2'),
				formatter: (v, z, root) => {
					let separator = this.$screen.width < 992 ? ' | ' : '<br>';
					return !this.showGridDetails ? root.hhmmOvt2 : root.hhmmOvt2 + separator + rendCurrency(root.salaryOvertime2);
				}
			};
			if (this.showOvt2()) {
				flds.push(ovt2Fld);
			}

			var transportFld = {
				key: 'hhmmTransport',
				label: this.FormMSG(158, 'Transport'),
				formatter: (v, z, root) => {
					let separator = this.$screen.width < 992 ? ' | ' : '<br>';
					return !this.showGridDetails ? root.hhmmTransport : root.hhmmTransport + separator + rendCurrency(root.salaryTransport);
				}
			};
			if (this.showTransport()) {
				flds.push(transportFld);
			}

			var nightFld = {
				key: 'hhmmNight',
				label: this.FormMSG(57, 'Night'),
				formatter: (v, z, root) => {
					let separator = this.$screen.width < 992 ? ' | ' : '<br>';
					return !this.showGridDetails ? root.hhmmNight : root.hhmmNight + separator + rendCurrency(root.salaryHourNight);
				}
			};
			if (this.showNightHours()) {
				flds.push(nightFld);
			}

			var tooEarlyHoursFld = {
				key: 'hhmmTooEarly',
				label: this.FormMSG(58, 'Anticipated'),
				formatter: (v, z, root) => {
					let separator = this.$screen.width < 992 ? ' | ' : '<br>';
					return !this.showGridDetails ? root.hhmmTooEarly : root.hhmmTooEarly + separator + rendCurrency(root.salaryHourTooEarly);
				}
			};
			if (this.showTooEarlyHours()) {
				flds.push(tooEarlyHoursFld);
			}

			var lunchflds = [
				{
					key: 'lunchStrTime',
					label: this.FormMSG(60, 'Lunch start'),
					formatter: (v, z, root) => {
						if (isNil(root)) return '';
						const time = root.lunchStrTime.split('T')[1].substring(0, 5);
						return root.lunchEndTime.substring(11, 15) == root.lunchStrTime.substring(11, 15)
							? ''
							: root.date.substring(8, 10) == root.lunchStrTime.substring(8, 10)
							? time
							: time + ' (' + root.lunchStrTime.substring(8, 10) + ')';
					},
					sortable: false
				},
				{
					key: 'lunchEndTime',
					label: this.FormMSG(65, 'Lunch end'),
					formatter: (v, z, root) => {
						if (isNil(root)) return '';
						const time = root.lunchEndTime.split('T')[1].substring(0, 5);
						//console.log("in formatter:",root)
						return root.lunchEndTime.substring(11, 15) == root.lunchStrTime.substring(11, 15)
							? ''
							: root.date.substring(8, 10) == root.lunchEndTime.substring(8, 10)
							? time
							: time + ' (' + root.lunchEndTime.substring(8, 10) + ')';
					},
					sortable: false
				}
			];
			if (this.showLunch()) {
				flds = flds.concat(lunchflds);
			}
			var lunchPd = {
				key: 'lunch',
				label: this.FormMSG(61, 'Lunch P/D'),
				formatter: (v, z, root) => {
					return v == false ? rendCurrency(0) : rendCurrency(root.lunchPerDiem);
				},
				sortable: false
			};
			if (this.showLunchPerDiem()) {
				flds.push(lunchPd);
			}
			var HotelPd = {
				key: 'hotel',
				label: this.FormMSG(62, 'Hotel P/D'),
				formatter: (v, z, root) => {
					return v == false ? rendCurrency(0) : rendCurrency(root.hotelPerDiem);
				},
				sortable: false
			};
			if (this.showHotelPerDiem()) {
				flds.push(HotelPd);
			}
			/**/
			var flds1 = [
				{
					key: 'dayRate',
					label: this.FormMSG(59, 'Rate'),
					formatter: (value) => {
						return value + ' %';
					},
					sortable: false
				},
				{
					key: 'kgCoTwo',
					label: 'CO2',
					formatter: (v) => rendKgCo2(v),
					sortable: true
				},
				{
					key: 'remark',
					label: this.FormMSG(5001, 'Comment'),
					sortable: false,
					class: 'text-center'
				},
				{
					key: 'salary',
					label: this.FormMSG(63, 'Salary'),
					formatter: (value) => rendCurrency(value),
					sortable: false
				},
				{
					key: 'rem',
					label: this.FormMSG(64, 'Remove'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center'
				}
			];
			flds = flds.concat(flds1);
			return flds;
		},
		tsfieldsFilterOptions() {
			return this.isFilmSingle
				? {}
				: {
						key: 'validated',
						choices: [
							{
								value: 'all',
								text: this.FormMSG(8009777, 'All')
							},
							{
								value: 0,
								text: this.FormMSG(897977, 'Not Submitted')
							},
							{
								value: 8,
								text: this.FormMSG(8977, 'Validated')
							}
						]
				  };
		}
	},
	created() {
		moment.locale(this.lang);
	},
	methods: {
		handleEditTsDay(item) {
			this.$router.push(`/timesheet?date=${item.date}`);
		},
		handleChangeTsDaySelected(payload, tsDay) {
			if (payload) {
				this.listTsDays.push(tsDay);
			} else {
				this.listTsDays = this.listTsDays.filter((tsDaySelected) => tsDaySelected.id !== tsDay.id);
			}
		},
		async sendForValidation() {
			const action = async () => {
				let tsDaysToSend = +this.currentTimesheet.id;
				await submitTimeSheetsSlice(tsDaysToSend).then((res) => {
					if (res) {
						let strTitle = this.FormMSG(14509, 'Success');
						this.createToastForMobile(strTitle, this.FormMSG(124101, 'All not submitted for validation send'), '', 'success', true);
						this.$router.go(-1);
					}
				});
			};
			this.confirmModal(action, this.FormMSG(4004, 'Please confirm you want to proceed?'));
		},
		async handleClickDeleteTsDay(tsDay) {
			let id = tsDay.id;
			const action = async () => {
				var TsDayId = parseInt(id, 10);
				//console.log("in delItem:",TsDayId);
				await delTimesheet(TsDayId)
					.then(async (result) => {
						this.createToastForMobile(this.FormMSG(16102, 'Success'), this.FormMSG(161240, 'Your days has been deleted'), '');
						this.handleChangeTsDaySelected(false, tsDay);
					})
					.catch((error) => {
						//console.log(error);
						this.erreur = error;
					});
			};
			this.confirmModal(action, this.FormMSG(11360, 'Do you really want to delete this time card?'));
		},
		getHours(val) {
			return val.split('T')[1].substring(0, 5);
		},
		rendDate(value) {
			return moment(value).format('DD/MM/YYYY');
		},
		changeToArray(value) {
			let result = [];
			result.push(value);
			return result;
		},
		getCO2(val) {
			return rendKgCo2(val);
		},
		getStatus(value) {
			const findIndex = this.statuses.findIndex((s) => parseInt(s.value) === parseInt(value));
			return this.statuses[findIndex].text;
		},
		showHotelPerDiem() {
			return store.getCurrentProjectConfig().useHotelPerDiem;
		},
		showLunchPerDiem() {
			return store.getCurrentProjectConfig().useLunchPerDiem && !this.curDay.stageDayUsed;
		},
		showLunch() {
			return store.getCurrentProjectConfig().useLunchTime;
		},
		showTooEarlyHours() {
			return store.getCurrentProjectConfig().useTooEarlyHours;
		},
		showNightHours() {
			return store.getCurrentProjectConfig().useNightHours;
		},
		showTransport() {
			//console.log("in show transport:",store.getCurrentProjectConfig());
			return store.getCurrentProjectConfig().useHomeToWorkTime;
		},
		showOvt2() {
			return store.getCurrentProjectConfig().useOvt2;
		},
		showOvt1() {
			return store.getCurrentProjectConfig().useOvt1;
		},
		showOvt() {
			return store.getCurrentProjectConfig().useOvertime;
		},
		formatterEndTime(value) {
			const time = value.endTime.split('T')[1].substring(0, 5);
			return value.endTime.substring(8, 10) == value.strTime.substring(8, 10) ? time : time + ' (' + value.endTime.substring(8, 10) + ')';
		},
		formatterStrTime(value) {
			return value.split('T')[1].substring(0, 5);
		},
		handleClickShare() {
			this.$emit('details-timesheet-mobile:share');
		},
		handleClickDownload() {
			this.$emit('details-timesheet-mobile:download');
		},
		getCurrency(val) {
			return rendCurrency(val);
		},
		getHhMmString(value) {
			let splitValue = value ? value.split(':') : '';
			if (parseInt(splitValue[0], 10) < 10) {
				splitValue[0] = '0' + parseInt(splitValue[0]);
			}
			if (parseInt(splitValue[1], 10) < 10) {
				splitValue[1] = '0' + parseInt(splitValue[1]);
			}
			return `${splitValue[0]}h ${splitValue[1]}m`;
		},
		showDinnerPerDiem() {
			return store.getCurrentProjectConfig().useDinnerPerDiem;
		},
		showAbroadPerDiem() {
			return store.getCurrentProjectConfig().useAbroadPerDiem;
		},
		getLucideIcon(name) {
			return icons[name];
		},
		handleClickBack() {
			if (this.$route.query.budgetSalaries) {
				this.$router.push({ path: '/budget?activeTabBudgetSalaries=1' });
			} else {
				let href = window.location.href.split('/')[4];
				let home = window.location.href.split`#/`[1].split`/`[0];
				if (href === 'timesheetsEntryCrew') {
					let home = window.location.href.split`#/`[1].split`/`[0];
					const path = `/${home}`;
					return this.$router.push({ path: path + `?activeSecondTab=1&activeFilter=${this.$route.query.activeFilter}` });
				}
				// console.log(this.$route.query);
				if (this.$route.query.previousActiveTab) {
					let home = window.location.href.split`#/`[1].split`/`[0];
					const path = `/${home}`;
					return this.$router.push({
						path: path,
						query: {
							activeTab: this.$route.query.previousActiveTab
						}
					});
				}

				return this.$router.go(-1);
			}
		},
		tooltipTsContentPerIdem(item) {
			let content = [];

			if (item.weeklyHotelPerDiem > 0) {
				content.push(this.FormMSG(103, 'Hotel: ') + this.getCurrency(item.weeklyHotelPerDiem));
			}

			if (item.weeklyLunchPerDiem > 0) {
				content.push(this.FormMSG(104, 'Lunch: ') + this.getCurrency(item.weeklyLunchPerDiem));
			}

			if (item.weeklyDinnerPerDiem > 0) {
				content.push(this.FormMSG(110, 'Dinner: ') + this.getCurrency(item.weeklyDinnerPerDiem));
			}

			if (item.weeklyAbroadPerDiem > 0) {
				content.push(this.FormMSG(117, 'Abroad: ') + this.getCurrency(item.weeklyAbroadPerDiem));
			}

			return content.join('<br />');
		},
		tooltipTsContentDailyOvertime(item) {
			let content = [];
			if (item.hhmmOvt !== '00:00') {
				content.push(this.FormMSG(114, 'Daily overtime: ') + this.getHhMmString(item.hhmmOvt));
			}
			if (item.hhmmOvt1 !== '00:00') {
				content.push(this.FormMSG(115, 'Daily overtime 1: ') + this.getHhMmString(item.hhmmOvt1));
			}
			if (item.hhmmOvt2 !== '00:00') {
				content.push(this.FormMSG(116, 'Daily overtime 2: ') + this.getHhMmString(item.hhmmOvt2));
			}
			return content.join('<br />');
		},
		tooltipTsContentWeeklyTotalOvertime(item) {
			let content = [];
			content.push(this.FormMSG(111, 'Weekly overtime: ') + this.getHhMmString(item.weeklyTotMinOvtStr));
			content.push(this.FormMSG(112, 'Weekly overtime 1: ') + this.getHhMmString(item.weeklyTotMinOvt1Str));
			content.push(this.FormMSG(113, 'Weekly overtime 2: ') + this.getHhMmString(item.weeklyTotMinOvt2Str));
			return content.join('<br />');
		},
		tooltipTsContentAllowance(item) {
			let content = [];

			if (item.weeklyCarAllowance > 0) {
				content.push(this.FormMSG(105, 'Car: ') + this.getCurrency(item.weeklyCarAllowance));
			}

			if (item.weeklyComputerAllowance > 0) {
				content.push(this.FormMSG(106, 'Computer: ') + this.getCurrency(item.weeklyComputerAllowance));
			}

			if (item.weeklyPhoneAllowance > 0) {
				content.push(this.FormMSG(107, 'Phone: ') + this.getCurrency(item.weeklyPhoneAllowance));
			}

			if (item.weeklyProductionFeeAllowance > 0) {
				content.push(this.FormMSG(108, 'Production fee: ') + this.getCurrency(item.weeklyProductionFeeAllowance));
			}

			if (item.weeklyBoxKitAllowance > 0) {
				content.push(this.FormMSG(109, 'Box kit: ') + this.getCurrency(item.weeklyBoxKitAllowance));
			}

			if (item.dailyTotAllowances > 0) {
				content.push(this.FormMSG(118, 'Total Daily: ') + this.getCurrency(item.dailyTotAllowances));
			}

			return content.join('<br />');
			return content.join('<br />');
		},
		tooltipContentPerIdem(item) {
			let content = [];
			if (item.hotel && item.hotelPerDiem > 0) {
				content.push(this.FormMSG(103, 'Hotel: ') + this.getCurrency(item.hotelPerDiem));
			}

			if (item.lunch && item.lunchPerDiem > 0) {
				content.push(this.FormMSG(104, 'Lunch: ') + this.getCurrency(item.lunchPerDiem));
			}

			if (item.useDinner && item.dinnerPerDiem > 0) {
				content.push(this.FormMSG(473, 'Dinner: ') + this.getCurrency(item.dinnerPerDiem));
			}

			if (item.useAbroad && item.abroadPerDiem > 0) {
				content.push(this.FormMSG(474, 'Abroad: ') + this.getCurrency(item.abroadPerDiem));
			}

			return content.join('<br />');
		},
		rendAllowanceType(type) {
			if (type == 0) {
				return this.FormMSG(1112, 'D');
			} else if (type == 1) {
				return this.FormMSG(1113, 'W');
			} else if (type == 2) {
				return this.FormMSG(1111, 'H');
			}
		},
		tooltipContentAllowance(item) {
			let content = [];
			if (item.carAllowance > 0) {
				content.push(
					this.FormMSG(1110, 'Car ') + '(' + this.rendAllowanceType(item.contract.carAllowanceRate) + '): ' + this.getCurrency(item.carAllowance)
				);
			}

			if (item.computerAllowance > 0) {
				content.push(
					this.FormMSG(1114, 'Computer ') +
						'(' +
						this.rendAllowanceType(item.contract.computerAllowanceRate) +
						'): ' +
						this.getCurrency(item.computerAllowance)
				);
			}

			if (item.phoneAllowance > 0) {
				content.push(
					this.FormMSG(1115, 'Phone ') +
						'(' +
						this.rendAllowanceType(item.contract.phoneAllowanceRate) +
						'): ' +
						this.getCurrency(item.phoneAllowance)
				);
			}

			if (item.productionFeeAllowance > 0) {
				content.push(
					this.FormMSG(1116, 'Production fee ') +
						'(' +
						this.rendAllowanceType(item.contract.productionFeeAllowanceRate) +
						'): ' +
						this.getCurrency(item.productionFeeAllowance)
				);
			}

			if (item.boxKitAllowance > 0) {
				content.push(
					this.FormMSG(1117, 'Box kit ') +
						'(' +
						this.rendAllowanceType(item.contract.boxKitAllowanceRate) +
						'): ' +
						this.getCurrency(item.boxKitAllowance)
				);
			}

			return content.join('<br />');
			return content.join('<br />');
		},
		showWeeklyOvt() {
			return store.getCurrentProjectConfig().useWeeklyOvt;
		},
		showWeeklyOvt1() {
			return store.getCurrentProjectConfig().useWeeklyOvt1;
		},
		showWeeklyOvt2() {
			return store.getCurrentProjectConfig().useWeeklyOvt2;
		},
		showPreCall() {
			return store.getCurrentProjectConfig().useBeforeCallTime;
		}
	}
};
</script>

<style scoped></style>
